import React, { useEffect, useState } from "react";
import axios from "axios";
import { api } from "../utils/Api";
import {
	Typography,
	Flex,
	notification,
	Statistic,
	Upload,
	DatePicker,
	Input,
	Row,
	Col,
	Table,
	Button,
} from "antd";
import Layout from "../layout/Layout";
import { CustomPicker, CustomSpinner } from "../utils/CustomComponents";
import { InboxOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;
const { Search } = Input;
const { Dragger } = Upload;
const { Text } = Typography;

const optionsTransaction = [
	{
		value: "All",
		label: "All",
	},
	{
		value: "Payment",
		label: "Payment",
	},
	{
		value: "Invoice",
		label: "Invoice",
	},
];

const optionsPaid = [
	{
		value: "All",
		label: "All",
	},
	{
		value: "Paid",
		label: "Paid",
	},
	{
		value: "Unpaid",
		label: "Unpaid",
	},
];

const TransactionDashboard = () => {
	const [loading, setLoading] = useState(true);
	const [tableLoading, setTableLoading] = useState(false);
	const [isUploadLoading, setIsUploadLoading] = useState(false);
	const [startDate, setStartDate] = useState(dayjs());
	const [endDate, setEndDate] = useState(dayjs());
	const [opportunityList, setOpportunityList] = useState([]);
	const [transactionType, setTransactionType] = useState("All");
	const [paid, setPaid] = useState("All");
	const [term, setTerm] = useState("");
	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "Client",
			dataIndex: "client",
			key: "client",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Transaction Type",
			dataIndex: "type",
			key: "type",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Num",
			dataIndex: "num",
			key: "num",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Amount",
			dataIndex: "amount",
			key: "amount",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Paid",
			dataIndex: "paid",
			key: "paid",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
	];

	useEffect(() => {
		getTransactionList(startDate, endDate, transactionType, paid, "");
	}, []);

	const getTransactionList = async (startDate, endDate, type, paid, term) => {
		setTableLoading(true);

		const start = `${startDate.year()}-${
			startDate.month() + 1
		}-${startDate.date()}`;
		const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;

		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url =
			api +
			`/transaction/list?startDate=${start}&endDate=${end}&type=${type}&paid=${paid}&term=${term}`;

		try {
			const { data } = await axios.get(url);
			let list = [];

			if (data.success) {
				data.payload.map((item, index) => {
					list.push({
						key: index,
						id: item._id,
						date: Intl.DateTimeFormat("en-US").format(new Date(item.date)),
						client: item.client,
						type: item.type,
						num: item.num,
						amount: item.amount,
						paid: item.paid ? "Paid" : "Unpaid",
					});
				});
				setOpportunityList(list);
			} else {
				openNotification("error", "Error", data.message);
			}

			setLoading(false);
			setTableLoading(false);
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const createTransactions = async (values) => {
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url = api + `/transaction/create`;

		try {
			const { data } = await axios.post(url, values);

			openNotification(
				data.success ? "success" : "error",
				data.success ? "Success" : "Error",
				data.message
			);
			setIsUploadLoading(false);
			getTransactionList(startDate, endDate, transactionType, paid, term);
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const handleFileUpload = (file) => {
		setIsUploadLoading(true);
		const reader = new FileReader();

		reader.onload = async (e) => {
			const binaryString = e.target.result;

			// Parse Excel file using XLSX library
			const workbook = XLSX.read(binaryString, { type: "binary" });

			// Assuming the first sheet is the one we want
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];

			// Convert the sheet to JSON
			const jsonData = XLSX.utils.sheet_to_json(worksheet, {
				header: 1,
			});

			if (jsonData.length > 0) {
				if (
					jsonData[0][0] !== "57 Concrete" &&
					jsonData[0][1] !== "Collections Report"
				) {
					openNotification(
						"error",
						"error",
						"Excel File must have the correct format"
					);
					setIsUploadLoading(false);

					return;
				}

				const dataWithoutFirstFourRows = jsonData.slice(5);

				const rows = dataWithoutFirstFourRows.map((row) => {
					return row;
				});

				let items = [];
				let newRow = {};
				let name = "";

				for (let i = 0; i < rows.length; i++) {
					const item = rows[i];
					if (i < rows.length - 1) {
						if (item.length > 0) {
							if (item.length === 1) {
								if (rows[i + 1].length === 1) {
									name = `${String(item[0]).trim()} ${String(
										rows[i + 1]
									).trim()}`;
									i += 2;
								} else {
									name = String(item[0]).trim();
								}
								newRow.client = name;
							} else {
								newRow.client = name;
								const date = item[1];
								const type = item[2];
								const num = item[3];
								const amount = item[4];
								const paid = item[5];
								if (type === "Payment") {
									newRow.dateString = date;
									newRow.type = "Payment";
									newRow.amount = amount;
									newRow.paid = paid === "Paid" ? true : false;
									if (num) {
										newRow.num = String(num).trim();
									}
								} else if (type === "Invoice") {
									newRow.dateString = date;
									newRow.type = "Invoice";
									newRow.num = String(num).trim();
									newRow.amount = amount;
									newRow.paid = paid === "Paid" ? true : false;
								}
								items.push(newRow);
								newRow = {};
							}
						} else {
							name = "";
							newRow = {};
						}
					}
				}
				await createTransactions(items);
			} else {
				setIsUploadLoading(false);

				openNotification(
					"warning",
					"Warning",
					"Excel File do not have any content"
				);
			}
		};

		reader.readAsBinaryString(file); // Read the file as binary string
		return false; // Prevent default upload behavior
	};

	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
		}
	};

	const onChangeTransactionPicker = (value) => {
		setTransactionType(value);
	};

	const onChangePaidPicker = (value) => {
		setPaid(value);
	};

	const onSearch = (value, _e, info) => {
		if (info?.source === "input") {
			setTerm(value);
			getTransactionList(startDate, endDate, transactionType, paid, value);
		}
	};

	const onChangeDays = (days) => {
		const today = dayjs();
		const prev = dayjs().subtract(days, "day");
		setStartDate(prev);
		setEndDate(today);
		getTransactionList(prev, today, transactionType, paid, term);
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				<Dragger
					name="file"
					multiple={false}
					beforeUpload={handleFileUpload}
					showUploadList={false}
					accept=".xlsx,.xls"
					disabled={isUploadLoading}
				>
					{isUploadLoading ? (
						<CustomSpinner />
					) : (
						<div>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								Click or drag file to this area to upload
							</p>
							<p className="ant-upload-hint">
								Support for a single xlsx file to upload transactions
							</p>
						</div>
					)}
				</Dragger>

				<Row gutter={[16, 8]}>
					<Col span={24} xs={24} md={8} xl={6}>
						<RangePicker
							defaultValue={[
								dayjs(startDate, "MM/DD/YYYY"),
								dayjs(endDate, "MM/DD/YYYY"),
							]}
							onChange={onRangeChange}
							format={"MM/DD/YYYY"}
							size="large"
							disabled={loading}
							allowClear={false}
							style={{ width: "100%" }}
						/>
					</Col>
					<Col span={24} xs={24} md={4}>
						<CustomPicker
							label={"Transaction Type"}
							options={optionsTransaction}
							onChange={onChangeTransactionPicker}
							loading={loading}
							defaultValue={"All"}
							width={"100%"}
							isMulti={false}
							value={transactionType}
						/>
					</Col>
					<Col span={24} xs={24} md={4}>
						<CustomPicker
							label={"Paid Type"}
							options={optionsPaid}
							onChange={onChangePaidPicker}
							loading={loading}
							defaultValue={"All"}
							width={"100%"}
							isMulti={false}
							value={paid}
						/>
					</Col>
					<Col span={24} xs={24} md={8}>
						<Search
							placeholder="Search"
							enterButton
							onSearch={onSearch}
							allowClear
							style={{
								width: "100%",
							}}
							size="large"
						/>
					</Col>
				</Row>

				<Flex gap={"middle"}>
					<Button type="primary" size="large" onClick={() => onChangeDays(7)}>
						Show Last 7 days
					</Button>
					<Button type="primary" size="large" onClick={() => onChangeDays(30)}>
						Show Last 30 days
					</Button>
					<Button type="primary" size="large" onClick={() => onChangeDays(60)}>
						Show Last 60 days
					</Button>
					<Button type="primary" size="large" onClick={() => onChangeDays(90)}>
						Show Last 90 days
					</Button>
				</Flex>

				<Table
					columns={columns}
					dataSource={opportunityList}
					pagination={{
						position: ["bottomRight"],
						pageSizeOptions: [10, 20, 50, 100, 200],
						defaultPageSize: 10,
						showSizeChanger: true,
					}}
					scroll={{
						x: 1300,
					}}
					style={{
						minWidth: "100%",
					}}
					loading={tableLoading}
					summary={summary}
				/>
			</Flex>
		);
	};

	const summary = () => {
		const paid = opportunityList.filter((i) => i.paid === "Paid");
		const unpaid = opportunityList.filter((i) => i.paid === "Unpaid");

		const totalPaid = paid.reduce((accum, val) => {
			return accum + val.amount;
		}, 0);

		const totalUnpaid = unpaid.reduce((accum, val) => {
			return accum + val.amount;
		}, 0);

		return (
			<>
				{totalPaid !== 0 && (
					<Table.Summary.Row>
						<Table.Summary.Cell colSpan={3}></Table.Summary.Cell>
						<Table.Summary.Cell>
							<Text style={{ fontSize: 14 }} strong>
								{"Total Paid"}
							</Text>
						</Table.Summary.Cell>
						<Table.Summary.Cell>
							<Statistic
								title=""
								value={totalPaid}
								style={{ fontWeight: "bold" }}
								precision={2}
								prefix={"$"}
								valueStyle={{ fontSize: 14 }}
							/>
						</Table.Summary.Cell>
					</Table.Summary.Row>
				)}
				{totalUnpaid !== 0 && (
					<Table.Summary.Row>
						<Table.Summary.Cell colSpan={3}></Table.Summary.Cell>
						<Table.Summary.Cell>
							<Text style={{ fontSize: 14 }} strong>
								{"Total Unpaid"}
							</Text>
						</Table.Summary.Cell>
						<Table.Summary.Cell>
							<Statistic
								title=""
								value={totalUnpaid}
								style={{ fontWeight: "bold" }}
								precision={2}
								prefix={"$"}
								valueStyle={{ fontSize: 14 }}
							/>
						</Table.Summary.Cell>
					</Table.Summary.Row>
				)}
			</>
		);
	};

	return (
		<Layout title="Transaction Dashboard" description="Dashboard">
			{contextHolder}
			<Flex vertical gap={"large"}>
				{loading ? <CustomSpinner /> : <RenderTable />}
			</Flex>
		</Layout>
	);
};

export default TransactionDashboard;
