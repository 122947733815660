// import React, { useState, useEffect } from "react";
// import GoogleMapReact from "google-map-react";
// import Marker from "./Marker";
// import axios from "axios";
// import { api } from "../utils/Api";
// import { CustomSpinner } from "../utils/CustomComponents";

// const AddressJob = (props) => {
// 	const [latitude, setLatitude] = useState(null);
// 	const [longitude, setLongitude] = useState(null);
// 	const [destLat, setDestLat] = useState("");
// 	const [destLng, setDestLng] = useState("");
// 	const [loading, setLoading] = useState(true);
// 	const [name, setName] = useState("");
// 	const [directions, setDirections] = useState([]);

// 	const googleApi =
// 		process.env.REACT_APP_GOOGLE_MAP_API ||
// 		"AIzaSyClyO950zwD8xsLYO7VHcyrnokEU6njbVs";

// 	useEffect(() => {
// 		if (props.plant) {
// 			getPlantInfo();
// 		}
// 	}, []);

// 	const fetchDirections = async (
// 		originLat,
// 		originLng,
// 		destinationLat,
// 		destinationLng
// 	) => {
// 		const url = api + `/job/get/directions`;
// 		const token = localStorage.getItem("token");
// 		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

// 		const { data } = await axios.get(url, {
// 			params: {
// 				origin: `${originLat},${originLng}`,
// 				destination: `${destinationLat},${destinationLng}`,
// 			},
// 		});

// 		if (data.routes && data.routes.length) {
// 			const route = data.routes[0].legs[0].steps.map((step) => ({
// 				lat: step.end_location.lat,
// 				lng: step.end_location.lng,
// 			}));

// 			let newRoutes = [{ lat: originLat, lng: originLng }, ...route];

// 			setDirections(newRoutes);
// 		}
// 	};

// 	const getPlantInfo = async () => {
// 		const url = api + `/plant/${props.plant}`;
// 		const token = localStorage.getItem("token");
// 		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

// 		try {
// 			const { data } = await axios.get(url);
// 			setLatitude(data.latitude);
// 			setLongitude(data.longitude);
// 			setName(`57Concrete ${data.name}`);
// 			if (props.lat && props.lng) {
// 				setDestLat(props.lat);
// 				setDestLng(props.lng);
// 				await fetchDirections(
// 					data.latitude,
// 					data.longitude,
// 					props.lat,
// 					props.lng
// 				);
// 			}
// 			setLoading(false);
// 		} catch (err) {
// 			console.error(err);
// 			return null;
// 		}
// 	};

// 	const defaultMap = {
// 		center: {
// 			lat: latitude,
// 			lng: longitude,
// 		},
// 		zoom: 10,
// 	};

// 	// show text in map
// 	const AnyReactComponent = ({ text }) => (
// 		<div style={{ width: 300 }}>{text}</div>
// 	);

// 	const renderPolylines = (map, maps) => {
// 		if (directions.length > 0) {
// 			let nonGeodesicPolyline = new maps.Polyline({
// 				path: directions,
// 				geodesic: false,
// 				strokeColor: "#000",
// 				strokeOpacity: 1,
// 				strokeWeight: 3,
// 			});
// 			nonGeodesicPolyline.setMap(map);

// 			fitBounds(map, maps);
// 		}
// 	};

// 	const fitBounds = (map, maps) => {
// 		var bounds = new maps.LatLngBounds();
// 		for (let marker of directions) {
// 			bounds.extend(new maps.LatLng(marker.lat, marker.lng));
// 		}
// 		map.fitBounds(bounds);
// 	};

// 	return (
// 		<div>
// 			<div style={{ height: "60vh", width: "100%" }}>
// 				{loading ? (
// 					<CustomSpinner />
// 				) : (
// 					<GoogleMapReact
// 						bootstrapURLKeys={{ key: googleApi, version: "weekly" }}
// 						options={{
// 							fullscreenControl: true,
// 							disableDefaultUI: true,
// 							cameraControl: true,
// 						}}
// 						defaultCenter={defaultMap.center}
// 						defaultZoom={defaultMap.zoom}
// 						yesIWantToUseGoogleMapApiInternals
// 						onGoogleApiLoaded={({ map, maps }) => renderPolylines(map, maps)}
// 					>
// 						<Marker lat={latitude} lng={longitude} color="red" />
// 						<AnyReactComponent lat={latitude} lng={longitude} text={name} />
// 						{destLat && <Marker lat={destLat} lng={destLng} color="green" />}
// 						{destLat && (
// 							<AnyReactComponent
// 								lat={destLat}
// 								lng={destLng}
// 								text={props.address}
// 							/>
// 						)}
// 					</GoogleMapReact>
// 				)}
// 			</div>
// 		</div>
// 	);
// };

// export default AddressJob;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { api } from "../utils/Api";
import { CustomSpinner } from "../utils/CustomComponents";
import {
	Map,
	TileLayer,
	Popup,
	Marker as LeafletMarker,
	Polyline,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css"; // Import the Routing Machine's CSS

const AddressJob = (props) => {
	const [latitude, setLatitude] = useState(undefined);
	const [longitude, setLongitude] = useState(undefined);
	const [destLat, setDestLat] = useState(props.lat);
	const [destLng, setDestLng] = useState(props.lng);
	const [loading, setLoading] = useState(true);
	const [name, setName] = useState("");
	const [directions, setDirections] = useState([]);
	const [center, setCenter] = useState([props.lat, props.lng]);

	useEffect(() => {
		if (props.plant) {
			getPlantInfo();
		} else {
			setLoading(false);
		}
	}, []);

	const getPlantInfo = async () => {
		const url = api + `/plant/${props.plant}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			setLatitude(data.latitude);
			setLongitude(data.longitude);
			setName(`57Concrete ${data.name}`);
			if (props.lat && props.lng) {
				setDestLat(props.lat);
				setDestLng(props.lng);
				await generateMap(data.latitude, data.longitude);
			}
			setLoading(false);
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	const generateMap = async (plantLat, plantLng) => {
		const startPoint = L.latLng(plantLat, plantLng);
		const endPoint = L.latLng(props.lat, props.lng);

		const apiKey = "5b3ce3597851110001cf6248083de09da919490b904e5198f0c4279a";

		fetch(
			`https://api.openrouteservice.org/v2/directions/driving-car?api_key=${apiKey}&start=${startPoint.lng},${startPoint.lat}&end=${endPoint.lng},${endPoint.lat}`
		)
			.then((response) => response.json())
			.then((data) => {
				const routeCoordinates = data.features[0].geometry.coordinates.map(
					(coord) => [
						coord[1], // Latitude
						coord[0], // Longitude
					]
				);

				setDirections(routeCoordinates);
				const midLat = (startPoint.lat + endPoint.lat) / 2;
				const midLng = (startPoint.lng + endPoint.lng) / 2;
				setCenter([midLat, midLng]);
			})
			.catch((error) => {
				console.error("Error fetching route:", error);
			});
	};

	const customIcon = new L.Icon({
		iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
		iconSize: [25, 41], // size of the icon
		iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
		popupAnchor: [1, -34], // point from which the popup should open
		shadowUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png",
		shadowSize: [41, 41], // size of the shadow
	});

	return (
		<div>
			{loading ? (
				<CustomSpinner />
			) : (
				<Map
					center={center}
					zoom={11}
					style={{ width: "100%", height: "500px" }}
				>
					<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
					{props.plant && (
						<LeafletMarker position={[latitude, longitude]} icon={customIcon}>
							<Popup>{name}</Popup>
						</LeafletMarker>
					)}
					<LeafletMarker position={[destLat, destLng]} icon={customIcon}>
						<Popup>Destination: {props.address}</Popup>
					</LeafletMarker>
					{props.plant && (
						<Polyline
							positions={directions}
							color="blue"
							weight={4}
							opacity={0.7}
						/>
					)}
				</Map>
			)}
		</div>
	);
};

export default AddressJob;
