import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../layout/Layout";
import DataGrid from "react-data-grid";
import DatePicker from "react-datepicker";
import Spinner from "../layout/Spinner";
import { CSVLink } from "react-csv";
import ClientPicker from "../client/ClientPicker";
import PsiPicker from "../psi/PsiPicker";
import SellerPicker from "../seller/SellerPicker";
import { api } from "../utils/Api";

const ReportJob = () => {
	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [dateChange, setDateChange] = useState(true);
	const [originalData, setOriginalData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const fechaIni = `${startDate.getFullYear()}-${
			startDate.getMonth() + 1
		}-${startDate.getDate()}`;
		let fechaFin = `${endDate.getFullYear()}-${
			endDate.getMonth() + 1
		}-${endDate.getDate()}`;

		// if (endDate < startDate) {
		//     setEndDate(startDate)
		//     fechaFin = `${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}`
		// }

		if (dateChange) {
			setLoading(true);
			getJobs(fechaIni, fechaFin);
		}
	}, [startDate, endDate, update]);

	const columna = [
		{ key: "customer", name: "Customer", resizable: true },
		{ key: "number", name: "Job No." },
		{ key: "date", name: "Date", resizable: true },
		{ key: "time", name: "Time", resizable: true },
		{ key: "shipAddress", name: "Address", resizable: true },
		{ key: "jobType", name: "Job Type", sortable: true },
		{ key: "loadYD3", name: "Load" },
		{ key: "targetYD3", name: "Target" },
		{ key: "jobStatus", name: "Status", resizable: true },
		{ key: "jobPoor", name: "Pour", resizable: true },
		{ key: "slump", name: "Slump" },
		{ key: "destinationMinutes", name: "Destination Time" },
		{ key: "psi", name: "PSI", resizable: true },
		{ key: "seller", name: "Seller", resizable: true },
		{ key: "balance", name: "Balance" },
		{ key: "plant", name: "Plant" },
		{ key: "subplant", name: "Subplant" },
	];

	const sortRows = (initialRows, sortColumn, sortDirection) => (rows) => {
		console.log("sorting...");
		const comparer = (a, b) => {
			if (sortDirection === "ASC") {
				return a[sortColumn] > b[sortColumn] ? 1 : -1;
			} else if (sortDirection === "DESC") {
				return a[sortColumn] < b[sortColumn] ? 1 : -1;
			}
		};
		return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
	};

	const getJobs = async (startDate, endDate) => {
		const url = api + `/jobscheduled?startDate=${startDate}&endDate=${endDate}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			setOriginalData(data);
			mapData(data);
			setColumns(columna);
			setLoading(false);
			// const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	// get only required attributes for report renderer
	const mapData = (data) => {
		const nuevo = data.map((dato) => {
			let customer = "NA";
			if (dato.customer !== null) {
				customer = dato.customer.customerNumber + " " + dato.customer.name;
			}

			let seller = "NA";
			if (dato.seller !== null) {
				seller = dato.seller.name;
			}

			return {
				comments: dato.comments,
				destinationMinutes: dato.destinationMinutes,
				balance: dato.balance,
				customer,
				seller,
				jobType: dato.jobType,
				jobStatus: dato.jobStatus,
				jobPoor: dato.jobPoor,
				psi: dato.psi,
				number: dato.number,
				date: `${new Date(dato.scheduledDate).toLocaleDateString()}`,
				time: `${new Date(dato.scheduledDate).toLocaleTimeString([], {
					timeStyle: "short",
				})}`,
				shipAddress: dato.shipAddress,
				loadYD3: dato.loadYD3,
				targetYD3: dato.targetYD3,
				slump: dato.slump,
				plant: dato.plant ? dato.plant.name : "NA",
				subplant: dato.subplant ? dato.subplant : "NA",
			};
		});

		setRows(nuevo);
	};

	const customerFilter = (selected) => {
		if (selected) {
			const filtrado = originalData.filter(
				(doc) => doc.customer._id === selected.value
			);
			mapData(filtrado);
			setDateChange(false);
			setUpdate(!update);
		} else {
			mapData(originalData);
			setDateChange(false);
			setUpdate(!update);
		}
	};

	const psiFilter = (selected) => {
		if (selected) {
			const filtrado = originalData.filter((doc) => doc.psi === selected.label);
			mapData(filtrado);
			setDateChange(false);
			setUpdate(!update);
		} else {
			mapData(originalData);
			setDateChange(false);
			setUpdate(!update);
		}
	};

	const sellerFilter = (selected) => {
		if (selected) {
			const filtrado = originalData.filter(
				(doc) => doc.seller.name === selected.label
			);
			mapData(filtrado);
			setDateChange(false);
			setUpdate(!update);
		} else {
			mapData(originalData);
			setDateChange(false);
			setUpdate(!update);
		}
	};

	const headers = () => {
		return columna.map((item) => {
			return {
				key: item.key,
				label: item.name,
			};
		});
	};

	return (
		<Layout title="Report" description="Jobs Report">
			<div>
				<div className="form-group row">
					<div className="col-sm-4">
						<label className="text-muted">Start Date</label>
						<DatePicker
							selected={startDate}
							onChange={(date) => {
								setStartDate(date);
								setDateChange(true);
							}}
						/>
					</div>
					<div className="col-sm-4">
						<label className="text-muted">End Date</label>
						<DatePicker
							selected={endDate}
							onChange={(date) => {
								setEndDate(date);
								setDateChange(true);
							}}
							minDate={startDate}
						/>
					</div>
				</div>
				<div className="form-group row" style={{ fontSize: "10px" }}>
					<form className="form">
						<div className="form-group row">
							<div>
								<label>Filters</label>
							</div>
							<div className="col-sm-3">
								<ClientPicker action={customerFilter} />
							</div>
							<div className="col-sm-3">
								<PsiPicker action={psiFilter} />
							</div>
							<div className="col-sm-3">
								<SellerPicker action={sellerFilter} />
							</div>
						</div>
					</form>
				</div>
			</div>
			{loading ? (
				<Spinner />
			) : (
				<div>
					<DataGrid
						className="rdg-light"
						columns={columns}
						rows={rows}
						// onGridSort={(sortColumn, sortDirection) =>
						//     setRows(sortRows(rows, sortColumn, sortDirection))
						//   }
					/>
					<CSVLink
						data={rows}
						headers={headers()}
						filename={"57concrete-report.csv"}
					>
						Download report
					</CSVLink>
				</div>
			)}
		</Layout>
	);
};

export default ReportJob;
