import {
	Button,
	Checkbox,
	Col,
	Divider,
	Form,
	Input,
	InputNumber,
	Radio,
	Row,
	Select,
	notification,
	Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { api } from "../utils/Api";
import axios from "axios";
import {
	customerTypeOptions,
	notifiableOptions,
	paymentTermOptions,
	paymentTypeOptions,
	quadrantTypeOptions,
} from "../../constants/Customer";
const { Option } = Select;
const { Title } = Typography;

const Client = (props) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(true);
	const [name, setName] = useState("");
	const [customerNumber, setCustomerNumber] = useState("");
	const [contactType, setContactType] = useState("Email");
	const [userId, setUserId] = useState("");
	const [creditHold, setCreditHold] = useState(false);
	const [initialValues, setInitialValues] = useState({});
	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	useEffect(() => {
		getClient();
	}, []);

	const getClient = async () => {
		const url = api + "/customer/" + props.match.params.id;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);

			let notifiableEvents = [];
			data.notifiableEvents.map((item) => {
				notifiableEvents.push({
					value: item.value,
					label: item.label,
				});
			});

			setInitialValues({
				...initialValues,
				name: data.name,
				email: data.email,
				sms: data.sms ? data.sms.substring(2) : "",
				notifiableEvents: notifiableEvents,
				customerNumber: data.customerNumber,
				address: data.address,
				city: data.city,
				state: data.state,
				zip: data.zip,
				contactFax: data.contactFax,
				customerType: data.customerType,
				contactName: data.contact ? data.contact.name : "",
				contactPhone: data.contact ? data.contact.phone.substring(2) : "",
				accountsPayableContact: data.accountsPayableContact,
				accountsPayableEmail: data.accountsPayableEmail,
				accountsPayablePhone: data.accountsPayablePhone
					? data.accountsPayablePhone.substring(2)
					: "",
				accountsPayableFax: data.accountsPayableFax,
				creditAmount: data.creditAmount ? data.creditAmount : 0,
				creditHold: data.creditHold ? data.creditHold : false,
				quadrantType: data.quadrantType ? data.quadrantType : "",
				prefix: "+1",
				contactType: data.contactType || "Email",
				paymentType: data.paymentType,
				paymentTerm: data.paymentTerm,
				creditRequested: data.creditRequested ? data.creditRequested : false,
				isActivePouredUser: data.contact
					? data.contact.isActivePouredUser
						? data.contact.isActivePouredUser
						: false
					: false,
				pouredEmail: data.contact
					? data.contact.pouredEmail
						? data.contact.pouredEmail
						: ""
					: "",
			});
			setCreditHold(data.creditHold ? data.creditHold : false);
			setUserId(data.contact ? data.contact._id : "");
			setCustomerNumber(data.customerNumber);
			setName(data.name);
			setLoading(false);
		} catch (err) {
			setLoading(false);
			openNotification("error", "Error", "Error while trying to get user");
			console.error(err.message);
			return null;
		}
	};

	const updateClient = async (datas) => {
		const url = api + "/customer/" + props.match.params.id;
		const url2 = api + "/user/customer/poured/update/" + userId;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		const client = {
			name: datas.name,
			email: datas.email,
			sms: datas.sms ? `+1${datas.sms}` : "",
			contactType: datas.contactType,
			address: datas.address,
			city: datas.city,
			state: datas.state,
			zip: datas.zip,
			contactPhone: `+1${datas.contactPhone}`,
			contactFax: datas.contactFax,
			customerType: datas.customerType,
			accountsPayableContact: datas.accountsPayableContact,
			accountsPayableEmail: datas.accountsPayableEmail,
			accountsPayablePhone: `+1${datas.accountsPayablePhone}`,
			accountsPayableFax: datas.accountsPayableFax,
			creditAmount: datas.creditAmount,
			paymentTerm: datas.paymentTerm,
			paymentType: datas.paymentType,
			creditRequested: datas.creditRequested,
			creditHold: datas.creditHold,
			quadrantType: datas.quadrantType,
		};

		const pouredClient = {
			isActivePouredUser: datas.isActivePouredUser,
			pouredEmail: datas.pouredEmail,
			pouredRole: "Customer",
		};

		if (datas.notifiableEvents) {
			let values = [];
			datas.notifiableEvents.map((item) => {
				values.push({
					value: item.value,
					label: item.label,
				});
			});
			client.notifiableEvents = values;
		}

		if (datas.isActivePouredUser) {
			if (datas.pouredEmail === "") {
				setLoading(false);
				openNotification(
					"warning",
					"Warning",
					"If Customer Poured is active, it needs an email"
				);
				return null;
			}
		}

		try {
			await axios.patch(url, client);
			if (userId !== "") await axios.patch(url2, pouredClient);
			openNotification("success", "Success", "Customer Updated");
			setLoading(false);
			props.history.push("/listacliente");
		} catch (err) {
			setLoading(false);
			openNotification("error", "Error", "Error while trying to update user");
			console.error(err.message);
			return null;
		}
	};

	const goCreateJob = () => {
		localStorage.setItem("customerName", name);
		localStorage.setItem("customerNumber", customerNumber);
		const url = "/jobnuevo";
		props.history.push(url);
	};

	const goPdfForm = () => {
		const url = "/clientpdf/" + props.match.params.id;
		props.history.push(url);
	};

	const updateHoldCredit = async () => {
		setLoading(true);
		const url = api + "/customer/" + props.match.params.id;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const body = {
				creditHold: !creditHold,
			};
			await axios.patch(url, body);
			openNotification("info", "Info", "Credit Hold Updated");
			setLoading(false);
			props.history.push("/listacliente");
		} catch (err) {
			setLoading(false);
			console.error(err.message);
			return null;
		}
	};

	const deleteClient = async () => {
		const url = api + "/customer/" + props.match.params.id;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			await axios.delete(url);
			openNotification("info", "Info", "Customer Deleted");
			setLoading(false);
			props.history.push("/listacliente");
		} catch (err) {
			setLoading(false);
			console.error(err.message);
			return null;
		}
	};

	const onChangeContactType = (e) => {
		setContactType(e.target.value);
	};

	const onFinish = (values) => {
		setLoading(true);
		updateClient(values);
	};

	const prefixSelector = (
		<Form.Item name="prefix" noStyle>
			<Select
				style={{
					width: 70,
				}}
			>
				<Option value="+1">+1</Option>
			</Select>
		</Form.Item>
	);

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const CustomForm = () => {
		return (
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				initialValues={initialValues}
			>
				<Row gutter={16}>
					<Col span={6}>
						<Form.Item label="Customer Number" name="customerNumber">
							<Input size="large" disabled />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							label="Company Name"
							name="name"
							rules={[
								{
									required: true,
									message: "Please fill company name",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							label="Customer Type"
							name="customerType"
							rules={[
								{
									required: true,
									message: "Please select customer type",
								},
							]}
						>
							<Select
								showSearch
								placeholder={`Select customer type`}
								optionFilterProp="children"
								size="large"
								filterOption={filterOption}
								options={customerTypeOptions}
								style={{
									width: "100%",
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Quadrant Type"
							name="quadrantType"
							rules={[
								{
									required: true,
									message: "Please select quadrant type",
								},
							]}
						>
							<Select
								showSearch
								placeholder={`Select quadrant type`}
								optionFilterProp="children"
								size="large"
								filterOption={filterOption}
								options={quadrantTypeOptions}
								style={{
									width: "100%",
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item label="Contact Name" name="contactName">
							<Input size="large" disabled />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Contact Phone" name="contactPhone">
							<Input size="large" addonBefore={prefixSelector} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={4}>
						<Form.Item
							name="contactType"
							label="Contact Type"
							rules={[
								{
									required: true,
									message: "Please pick a type!",
								},
							]}
						>
							<Radio.Group onChange={onChangeContactType} size="large">
								<Radio.Button value="SMS">SMS</Radio.Button>
								<Radio.Button value="Email">Email</Radio.Button>
							</Radio.Group>
						</Form.Item>
					</Col>
					{contactType === "Email" ? (
						<Col span={20}>
							<Form.Item
								label="Contact Email"
								name="email"
								rules={[
									{
										type: "email",
										message: "The input is not valid E-mail",
									},
									{
										required: true,
										message: "Please fill email",
									},
								]}
							>
								<Input size="large" />
							</Form.Item>
						</Col>
					) : (
						<Col span={20}>
							<Form.Item
								label="Customer Phone"
								name="sms"
								rules={[
									{
										required: true,
										message: "Please fill phone",
									},
									{
										len: 10,
										max: 10,
										message: "Phone number must have 10 numbers",
									},
								]}
							>
								<Input size="large" addonBefore={prefixSelector} />
							</Form.Item>
						</Col>
					)}
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item label="Notifiable Events" name="notifiableEvents">
							<Select
								showSearch
								labelInValue
								placeholder={`Select notifiable events`}
								size="large"
								mode="multiple"
								options={notifiableOptions}
								style={{
									width: "100%",
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							label="Company Address"
							name="address"
							rules={[
								{
									required: true,
									message: "Please fill address",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							label="Company City"
							name="city"
							rules={[
								{
									required: true,
									message: "Please fill city",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Company State"
							name="state"
							rules={[
								{
									required: true,
									message: "Please fill state",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							label="Company ZIP"
							name="zip"
							rules={[
								{
									required: true,
									message: "Please fill zip",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Contact Fax Number" name="contactFax">
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							label="A/P Contact Name"
							name="accountsPayableContact"
							rules={[
								{
									required: true,
									message: "Please fill contact name",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="A/P Contact Email"
							name="accountsPayableEmail"
							rules={[
								{
									type: "email",
									message: "The input is not valid E-mail",
								},
								{
									required: true,
									message: "Please fill contact email",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							label="A/P Contact Phone"
							name="accountsPayablePhone"
							rules={[
								{
									required: true,
									message: "Please fill phone",
								},
								{
									len: 10,
									max: 10,
									message: "Phone number must have 10 numbers",
								},
							]}
						>
							<Input size="large" addonBefore={prefixSelector} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="A/P Contact Fax" name="accountsPayableFax">
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					{localStorage.getItem("role") === "Finance" && (
						<Col span={12}>
							<Form.Item
								label="Payment Type"
								name="paymentType"
								rules={[
									{
										required: true,
										message: "Please select payment type",
									},
								]}
							>
								<Select
									showSearch
									placeholder={`Select payment type`}
									optionFilterProp="children"
									size="large"
									filterOption={filterOption}
									options={paymentTypeOptions}
									style={{
										width: "100%",
									}}
								/>
							</Form.Item>
						</Col>
					)}
					<Col span={12}>
						<Form.Item
							label="Payment Term"
							name="paymentTerm"
							rules={[
								{
									required: true,
									message: "Please select payment term",
								},
							]}
						>
							<Select
								showSearch
								placeholder={`Select payment term`}
								optionFilterProp="children"
								size="large"
								filterOption={filterOption}
								options={paymentTermOptions}
								style={{
									width: "100%",
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item label="Credit Amount" name="creditAmount">
							<InputNumber size="large" style={{ width: "100%" }} />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={16}>
					<Col span={6}>
						<Form.Item name="creditRequested" valuePropName="checked">
							<Checkbox>Request Credit</Checkbox>
						</Form.Item>
					</Col>
					{/* {(localStorage.getItem("email") === "mv@57concrete.com" ||
						localStorage.getItem("email") === "ec@57concrete.com" ||
						localStorage.getItem("email") === "cdl@57concrete.com" ||
						localStorage.getItem("email") === "bp@57concrete.com" ||
						localStorage.getItem("email") === "mb@57concrete.com" ||
						localStorage.getItem("email") === "eliud@gmail.com") && ( */}
					<Col span={6}>
						<Form.Item name="creditHold" valuePropName="checked">
							<Checkbox
								disabled={
									!(
										localStorage.getItem("email") === "mv@57concrete.com" ||
										localStorage.getItem("email") === "ec@57concrete.com" ||
										localStorage.getItem("email") === "cdl@57concrete.com" ||
										localStorage.getItem("email") === "bp@57concrete.com" ||
										localStorage.getItem("email") === "mb@57concrete.com" ||
										localStorage.getItem("email") === "vo@57concrete.com" ||
										localStorage.getItem("email") === "eliud@gmail.com"
									)
								}
							>
								Hold Credit
							</Checkbox>
						</Form.Item>
					</Col>
					{/* )} */}
				</Row>
				{userId !== "" && (
					<>
						<Divider style={{ margin: "12px 0px" }} />
						<Title level={3}>Customer Poured Details</Title>
						<Row gutter={16}>
							<Col span={24}>
								<Form.Item name="isActivePouredUser" valuePropName="checked">
									<Checkbox>Is Active Poured User?</Checkbox>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label="Poured Email"
									name="pouredEmail"
									rules={[
										{
											type: "email",
											message: "The input is not valid E-mail",
										},
									]}
								>
									<Input size="large" />
								</Form.Item>
							</Col>
						</Row>
					</>
				)}
				<Row gutter={16}>
					<Col>
						<Button type="primary" htmlType="submit" loading={loading}>
							Save
						</Button>
					</Col>
					{/* <Col>
                    <Popconfirm
                        title="Delete the customer"
                        description="Are you sure to delete this customer?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={deleteClient}
                    >
                        <Button danger type='primary' htmlType='button' loading={loading}>Delete</Button>
                    </Popconfirm>
                </Col> */}
					<Col>
						<Button
							type="default"
							htmlType="button"
							loading={loading}
							onClick={goCreateJob}
						>
							Create Job
						</Button>
					</Col>
					<Col>
						<Button
							type="primary"
							danger
							htmlType="button"
							loading={loading}
							onClick={goPdfForm}
						>
							PDF
						</Button>
					</Col>
					{(localStorage.getItem("email") === "mv@57concrete.com" ||
						localStorage.getItem("email") === "ec@57concrete.com" ||
						localStorage.getItem("email") === "cdl@57concrete.com" ||
						localStorage.getItem("email") === "bp@57concrete.com" ||
						localStorage.getItem("email") === "mb@57concrete.com" ||
						localStorage.getItem("email") === "vo@57concrete.com" ||
						localStorage.getItem("email") === "eliud@gmail.com") && (
						<Col>
							<Button
								type="primary"
								htmlType="button"
								loading={loading}
								onClick={updateHoldCredit}
							>
								{creditHold ? "Remove Hold Credit" : "Set Hold Credit"}
							</Button>
						</Col>
					)}
				</Row>
			</Form>
		);
	};

	return (
		<Layout title="Customer" description="Edit Customer" type="medium">
			{contextHolder}
			<CustomForm />
		</Layout>
	);
};

export default Client;
