import React, { useState } from "react";
import Layout from "../layout/Layout";
import {
	Button,
	Col,
	Divider,
	Flex,
	Form,
	Input,
	InputNumber,
	Modal,
	notification,
	Radio,
	Row,
	Select,
	Typography,
	DatePicker,
	Checkbox,
	Popconfirm,
} from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { api } from "../utils/Api";
import axios from "axios";
import ClientModal2 from "../client/ClientModal2";
import dayjs from "dayjs";
import {
	jobPourOptions,
	jobTypeOptions,
	slumpOptions,
} from "../../constants/Job";
import { cityList } from "../utils/CityList";
import AddressGeolocation2 from "../map/AddressGeolocation2";
import ForemanModal from "../foreman/ForemanModal";
import { paymentTypeOptions } from "../../constants/Customer";
import { CustomSpinner, CustomTable } from "../utils/CustomComponents";
import ReadLoad2 from "./ReadLoad2";
import JobCheckIn from "./JobCheckIn";

const { Title, Text } = Typography;

const jobStatusOptions = [
	{ value: "Trouble", label: "Trouble" },
	{ value: "ok", label: "Poured" },
	{ value: "Confirm", label: "A/R Confirmed" },
	{ value: "Operations done (AR)", label: "Operations done (AR)" },
	{
		value: "Scheduled",
		label: "Scheduled",
		disabled: !(
			localStorage.getItem("email") === "dm@57concrete.com" ||
			localStorage.getItem("email") === "rs@57concrete.com" ||
			localStorage.getItem("email") === "jp@57concrete.com" ||
			localStorage.getItem("email") === "vm@57concrete.com" ||
			localStorage.getItem("email") === "ec@57concrete.com" ||
			localStorage.getItem("email") === "eliud@gmail.com"
		),
	},
	{ value: "Inactive", label: "Inactive" },
	{ value: "Company cancellation", label: "Company cancellation" },
	{ value: "Customer cancellation", label: "Customer cancellation" },
	{ value: "Date moved", label: "Date moved" },
	{ value: "Active", label: "Pouring" },
	{ value: "Ready to Load", label: "Ready to be scheduled" },
	{ value: "Quote", label: "Quote" },
	{ value: "Waiting Inspection", label: "Waiting Inspection" },
];

const columns = [
	{
		title: "Date",
		dataIndex: "date",
		key: "date",
		render: (value) => {
			return <Text>{value}</Text>;
		},
	},
	{
		title: "User",
		dataIndex: "user",
		key: "user",
		render: (value) => {
			return <Text>{value}</Text>;
		},
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		render: (value) => {
			return <Text>{value}</Text>;
		},
	},
];

const Job2 = (props) => {
	const [loading, setLoading] = useState(true);
	const [loadingComponents, setLoadingComponents] = useState(true);
	const [loadingCustomer, setLoadingCustomer] = useState(true);
	const [loadingSeller, setLoadingSeller] = useState(true);
	const [loadingPsi, setLoadingPsi] = useState(true);
	const [loadingPlant, setLoadingPlant] = useState(true);
	const [loadingForeman, setLoadingForeman] = useState(true);
	const [dateMax, setDateMax] = useState(dayjs());
	const [minDateScheduledDate, setMinDateScheduledDate] = useState(dayjs());
	const [disabledDate, setDisabledDate] = useState(true);
	const [dateChanged, setDateChanged] = useState(false);
	const [isOver30, setIsOver30] = useState(false);
	const [checkInValues, setCheckInValues] = useState({});
	const [currentMeasurement, setCurrentMeasurement] = useState(false);
	const [checkInLatitude, setCheckInLatitude] = useState(undefined);
	const [checkInLongitude, setCheckInLongitude] = useState(undefined);
	const [jobStatusHistory, setJobStatusHistory] = useState([]);
	const [jobStatusCurrent, setJobStatusCurrent] = useState("");
	const [jobStatusPrev, setJobStatusPrev] = useState("");
	const [loads, setLoads] = useState("");
	const [loadYD3, setLoadYD3] = useState(0);
	const [addressChanged, setAddressChanged] = useState(false);
	const [sellers, setSellers] = useState([]);
	const [sellerPhone, setSellerPhone] = useState("");
	const [customers, setCustomers] = useState([]);
	const [psi, setPsi] = useState([]);
	const [plants, setPlants] = useState([]);
	const [foremans, setForemans] = useState([]);
	const [initialValues, setInitialValues] = useState({});
	const [openNewCustomerModal, setOpenNewCustomerModal] = useState(false);
	const [openSearchAddressModal, setOpenSearchAddressModal] = useState(false);
	const [openNewForemanModal, setOpenNewForemanModal] = useState(false);
	const [openLoadsModal, setOpenLoadsModal] = useState(false);
	const [openCheckInModal, setOpenCheckInModal] = useState(false);
	const [form] = Form.useForm();

	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	useEffect(() => {
		getLocation();
		getAllSelectValues();
	}, []);

	const getLocation = () => {
		if (navigator.geolocation) {
			// Success callback
			navigator.geolocation.getCurrentPosition(
				(position) => {
					setCheckInLatitude(position.coords.latitude);
					setCheckInLongitude(position.coords.longitude);
				},
				// Error callback
				(error) => {
					setCheckInLatitude(undefined);
					setCheckInLongitude(undefined);
					openNotification("warning", "Warning", error.message);
				}
			);
		} else {
			setCheckInLatitude(undefined);
			setCheckInLongitude(undefined);
			openNotification(
				"warning",
				"Warning",
				"Geolocation is not supported by this browser."
			);
		}
	};

	const getAllSelectValues = async () => {
		await Promise.all([
			getCustomers(),
			getSellers(),
			getPsi(),
			getPlants(),
			getForemans(),
		]).then(async (values) => {
			setLoadingComponents(false);
			await getJob(values[2]);
			await getLoadData();
			setLoading(false);
		});
	};

	const getLoadData = async () => {
		const url = api + "/load/" + props.match.params.id;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			setLoads(data);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const getJob = async (psis) => {
		const url = api + "/job/" + props.match.params.id;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);

			setSellers((prev) => [
				...prev,
				{ value: data.seller._id, label: data.seller.name },
			]);

			setInitialValues({
				approved: data.approved || false,
				prefix: "+1",
				number: data.number,
				customer: data.customer._id,
				paymentType: data.customer.paymentType,
				seller: data.seller._id,
				jobStatus: data.jobStatus,
				jobType: data.jobType,
				jobPoor: data.jobPoor,
				name: data.name,
				jobDescription: data.jobDescription,
				psi: psis.find((i) => i.label === data.psi).value,
				psiId: psis.find((i) => i.label === data.psi).value.split(",")[0],
				psiName: psis.find((i) => i.label === data.psi).label,
				plant: data.plant,
				shipAddress: data.shipAddress,
				shipCity: data.shipCity.toLowerCase(),
				city: data.shipCity,
				latitude: data.latitude,
				longitude: data.longitude,
				destinationTime: data.destinationTime,
				distance: data.distance,
				foremanItem: `${data.foreman},${data.jobForeman},${data.foremanPhone}`,
				foreman: data.foreman,
				jobForeman: data.jobForeman,
				foremanPhone: data.foremanPhone
					? data.foremanPhone.slice(2)
					: undefined,
				supervisorName: data.supervisorName || undefined,
				supervisorPhone: data.supervisorPhone
					? data.supervisorPhone.slice(2)
					: undefined,
				projectManager: data.projectManager || undefined,
				pmPhone: data.pmPhone ? data.pmPhone.slice(2) : undefined,
				apContact: data.apContact || undefined,
				apPhone: data.apPhone ? data.apPhone.slice(2) : undefined,
				taxExemptJob: data.taxExemptJob,
				targetYD3: data.targetYD3,
				balance: data.balance || undefined,
				jobDuration: data.jobDuration || undefined,
				scheduledDate: dayjs(new Date(data.scheduledDate)),
				inspectionTimeStamp: dayjs(new Date(data.inspectionTimeStamp)),
				logisticDate: data.logisticDate
					? dayjs(new Date(data.logisticDate))
					: undefined,
				fiberQuantity: data.fiberQuantity || undefined,
				unitPrice: data.unitPrice,
				taxPrice: data.unitPrice * 1.0825,
				paid: data.paid || false,
				fuelSurchargeGlobal: data.fuelSurchargeGlobal || false,
				shippingGlobal: data.shippingGlobal || false,
				fuelSurcharge: data.fuelSurcharge,
				deliveryFee: data.deliveryFee,
				comments: data.comments,
				slump: data.slump,
				qualityControl: data.qualityControl || false,
				// psiId: psi.find((i) => i.name === data.psi)
			});

			let sumYards = 0;
			if (data.targetYD3) {
				sumYards += data.targetYD3;
			}
			if (data.balance) {
				sumYards += data.balance;
			}

			if (sumYards < 30) {
				setIsOver30(false);
			} else if (sumYards >= 30) {
				setIsOver30(true);
				setCurrentMeasurement(data.measurement || false);

				setCheckInValues({
					measurement: data.measurement || false,
					measurementYards: data.measurementYards || undefined,
					readyForTruck: data.readyForTruck || false,
					readyForTruckComments: data.readyForTruckComments || undefined,
					lightTowerPositions: data.lightTowerPositions || false,
					lightTowerPositionsNumberTag:
						data.lightTowerPositionsNumberTag || undefined,
					lightTowerPositionsBrand: data.lightTowerPositionsBrand || undefined,
					pour: data.pour || false,
					jobStatus: data.jobStatus,
				});
			}

			setMinDateScheduledDate(
				data.hotShot
					? dayjs(new Date(data.scheduledDate))
					: dayjs(new Date(data.scheduledDate)).add(36, "hour")
			);
			setDateMax(dayjs(new Date(data.scheduledDate)));

			getDatePermissions(data.jobStatus);
			setSellerPhone(data.seller ? data.seller.phone : "");

			setJobStatusPrev(data.jobStatus);
			setJobStatusCurrent(data.jobStatus);
			setLoadYD3(data.loadYD3);

			const jobHistory = [];

			data.jobStatusHistory.map((item, index) => {
				jobHistory.push({
					key: index,
					date: Intl.DateTimeFormat("en-US").format(new Date(item.createdAt)),
					user: item.user.name,
					status: changeStatusLabels(item.jobStatus),
				});
			});

			setJobStatusHistory(jobHistory);
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	const changeStatusLabels = (jobStatus) => {
		if (jobStatus === "ok") {
			return "Poured";
		} else if (jobStatus === "Confirm") {
			return "A/R Confirmed";
		} else if (jobStatus === "Active") {
			return "Pouring";
		} else if (jobStatus === "Ready to Load") {
			return "Ready to be scheduled";
		} else {
			return jobStatus;
		}
	};

	const getDatePermissions = (status) => {
		if (
			localStorage.getItem("email") === "mv@57concrete.com" ||
			localStorage.getItem("email") === "vm@57concrete.com" ||
			localStorage.getItem("email") === "ecavazos@57concrete.com" ||
			localStorage.getItem("email") === "ga@57concrete.com" ||
			localStorage.getItem("email") === "9565660769@vendedor.com" ||
			localStorage.getItem("email") === "sdf@gmail.com" ||
			localStorage.getItem("email") === "jp@57concrete.com" ||
			localStorage.getItem("email") === "eliud@gmail.com"
		) {
			setDisabledDate(false);
		} else {
			if (
				status === "New Order" ||
				status === "Wait Inspection" ||
				status === "Waiting Inspection"
			) {
				setDisabledDate(false);
			} else {
				setDisabledDate(true);
			}
		}
	};

	const getCustomers = async () => {
		const url = api + "/customer/list/options";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);

			if (data.success) {
				const payload = data.payload;
				let list = [];
				payload.map((item) => {
					list.push({
						label: `${item.customerNumber} ${item.name}`,
						value: item._id,
					});
				});
				setCustomers(list);
				setLoadingCustomer(false);
			} else {
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const getSellers = async () => {
		const url = api + "/user/list/role/Seller";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);

			if (data.success) {
				const payload = data.payload;
				let list = [];
				payload.map((item) => {
					list.push({
						label: item.name,
						value: item._id,
					});
				});
				setSellers(list);
				setLoadingSeller(false);
			} else {
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const getPsi = async () => {
		const url = api + "/psi";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			data.map((item) => {
				list.push({
					value: `${item._id},${item.price}`,
					label: item.psi,
				});
			});
			setPsi(list);
			setLoadingPsi(false);
			return list;
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const getPlants = async () => {
		const url = api + "/plant";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			data.map((item) => {
				list.push({
					value: item._id,
					label: item.name,
				});
			});
			setPlants(list);
			setLoadingPlant(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const getForemans = async () => {
		const url = api + `/foreman`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			const optionsAux = [];
			data.forEach((foreman) => {
				let value = `${foreman._id},${foreman.firstName} ${foreman.lastName},${foreman.phone}`;
				let aux = `${foreman.firstName} ${foreman.lastName}`;
				let label = aux.toUpperCase();
				let obj = {
					value: value,
					label: label,
				};
				if (foreman.active) optionsAux.push(obj);
			});
			setForemans(optionsAux);
			setLoadingForeman(false);
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const handleNewCustomerOpen = () => {
		setOpenNewCustomerModal(true);
	};

	const handleNewCustomerCancel = () => {
		setOpenNewCustomerModal(false);
	};

	const onNewClientNew = async (newClient) => {
		setLoadingCustomer(true);
		await getCustomers();
		form.setFieldValue("customer", newClient);
		setOpenNewCustomerModal(false);
	};

	const RenderNewCustomerModal = () => {
		return (
			<Modal
				title="New Customer"
				open={openNewCustomerModal}
				onCancel={handleNewCustomerCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				width={600}
			>
				<ClientModal2 pcb={onNewClientNew} />
			</Modal>
		);
	};

	const onChangeJobStatus = (selectedJobStatus) => {
		if (selectedJobStatus) {
			setJobStatusCurrent(selectedJobStatus.value);
			form.setFieldValue("jobStatus", selectedJobStatus.value);
			form.setFieldValue("approved", true);
			setCheckInValues((prev) => ({
				...prev,
				jobStatus: selectedJobStatus.value,
			}));
			if (selectedJobStatus.value === "Ready to Load") {
				form.setFieldValue("readyToBeScheduledDate", dayjs().toDate());
				if (
					!(
						localStorage.getItem("email") === "eliud@gmail.com" ||
						localStorage.getItem("email") === "ec@57concrete.com" ||
						localStorage.getItem("email") === "bp@57concrete.com" ||
						localStorage.getItem("email") === "jp@57concrete.com" ||
						localStorage.getItem("email") === "vm@57concrete.com" ||
						localStorage.getItem("email") === "dm@57concrete.com" ||
						localStorage.getItem("email") === "rs@57concrete.com"
					)
				) {
					if (!checkInValues.measurement) {
						handleCheckInOpen();
					}
				}
			}
		}
	};

	const onChangePsi = (selectedPsi) => {
		if (selectedPsi) {
			let values = selectedPsi.value.split(",");
			form.setFieldsValue({
				psiPrice: values[1],
				unitPrice: values[1],
				taxPrice: values[1] * 1.0825,
				psiName: selectedPsi.label,
				psi: selectedPsi.value,
				psiId: values[0],
			});
		}
	};

	const onChangeCity = (selectedCity) => {
		if (selectedCity) {
			form.setFieldValue("shipCity", selectedCity.value);
			form.setFieldValue("city", selectedCity.label);
		}
	};

	const handleSearchAddressCancel = () => {
		setOpenSearchAddressModal(false);
	};

	const handleSearchAddressOpen = () => {
		setOpenSearchAddressModal(true);
	};

	const handleCallback = (childData) => {
		form.setFieldsValue({
			shipAddress: childData.address,
			destinationTime: childData.duration,
			distance: childData.distance,
			latitude: childData.destLat,
			longitude: childData.destLng,
			shipCity: childData.locality.toLowerCase(),
		});
		handleSearchAddressCancel();
		setAddressChanged(true);

		let cityFound = cityList.find((city) => city.label === childData.locality);
		if (typeof cityFound !== "undefined") {
			form.setFieldValue("city", childData.locality);
		} else {
			openNotification(
				"warning",
				"Warning",
				`The city ${childData.locality} is not defined in list, please report to IT team`
			);
		}
	};

	const RenderSearchAddressModal = () => {
		return (
			<Modal
				title="Address"
				open={openSearchAddressModal}
				onCancel={handleSearchAddressCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				width={600}
			>
				<AddressGeolocation2
					search={true}
					parentCallback={handleCallback}
					single={false}
					plant={form.getFieldValue("plant")}
					currentLocation={false}
				/>
			</Modal>
		);
	};

	const prefixSelector = (
		<Form.Item name="prefix" noStyle>
			<Select
				style={{
					width: 70,
				}}
			>
				<Select.Option value="+1">+1</Select.Option>
			</Select>
		</Form.Item>
	);

	const onChangeForeman = (selectedForeman) => {
		if (selectedForeman) {
			let option = selectedForeman.split(",");
			let foremanID = option[0];
			let foremanName = option[1];
			let foremanPhone = option[2];
			form.setFieldsValue({
				foremanPhone: foremanPhone.slice(2),
				jobForeman: foremanName,
				foreman: foremanID,
			});
		}
	};

	const handleNewForemanCancel = () => {
		setOpenNewForemanModal(false);
	};

	const handleNewForemanOpen = () => {
		setOpenNewForemanModal(true);
	};

	const onNewFormanNew = async (newForeman) => {
		setLoadingForeman(true);
		await getForemans();
		let selected = `${newForeman.foreman},${
			newForeman.firstName + " " + newForeman.lastName
		},${newForeman.phone}`;
		form.setFieldsValue({
			jobForeman: newForeman.firstName + " " + newForeman.lastName,
			foremanPhone: newForeman.phone.slice(2),
			foreman: newForeman.foreman,
			foremanItem: selected,
		});
		handleNewForemanCancel();
	};

	const RenderNewForemanModal = () => {
		return (
			<Modal
				title="New Foreman"
				open={openNewForemanModal}
				onCancel={handleNewForemanCancel}
				cancelButtonProps={{
					style: { display: "none" },
				}}
				okButtonProps={{
					style: { display: "none" },
				}}
				width={600}
			>
				<ForemanModal parentCallback={onNewFormanNew} />
			</Modal>
		);
	};

	const RenderShowLoadsModal = () => {
		return (
			<Modal
				title="Loads"
				open={openLoadsModal}
				onCancel={handleShowLoadsCancel}
				cancelButtonProps={{
					style: { display: "none" },
				}}
				okButtonProps={{
					style: { display: "none" },
				}}
				width={600}
			>
				<ReadLoad2
					loads={loads}
					jobID={form.getFieldValue("number")}
					loadTotal={loadYD3}
				/>
			</Modal>
		);
	};

	const RenderCheckInModal = () => {
		return (
			<Modal
				title="Job Check In"
				open={openCheckInModal}
				onCancel={handleCheckInCancel}
				cancelButtonProps={{
					style: { display: "none" },
				}}
				okButtonProps={{
					style: { display: "none" },
				}}
				width={600}
			>
				<JobCheckIn
					values={checkInValues}
					setCheckInValues={setCheckInValues}
					handleCheckInCancel={handleCheckInCancel}
				/>
			</Modal>
		);
	};

	const range = (start, end) => {
		const result = [];
		for (let i = start; i < end; i++) {
			result.push(i);
		}
		return result;
	};

	const onChangeDate = (value) => {
		setDateMax(value);
		setDateChanged(true);
		form.setFieldValue("scheduledDate", value);
		form.setFieldValue("jobStatus", "Date Moved");
		form.setFieldValue("logisticDate", undefined);
	};

	const disabledDateTime = (date) => {
		const scheduledDateDay = dayjs(dateMax).day();
		const currentDateDay = dayjs(date).day();
		if (scheduledDateDay === currentDateDay) {
			const scheduledDateMinute = dayjs(dateMax).minute();
			const scheduledDateHour = dayjs(dateMax).hour() + 1;
			return {
				disabledMinutes: () => range(scheduledDateMinute, 60),
				disabledHours: () => range(scheduledDateHour, 24),
			};
		} else {
			return {
				disabledMinutes: () => [],
				disabledHours: () => [],
			};
		}
	};

	const onChangeUnitPrice = (value) => {
		// let value = e.target.value;
		if (value !== null || value !== undefined) {
			if (value >= 0 && value <= 2000) {
				form.setFieldValue("taxPrice", value * 1.0825);
			}
		} else {
			form.setFieldValue("taxPrice", 0);
		}
	};

	const handleShowLoadsCancel = () => {
		setOpenLoadsModal(false);
	};

	const handleShowLoadsOpen = () => {
		setOpenLoadsModal(true);
	};

	const handleCheckInCancel = () => {
		setOpenCheckInModal(false);
	};

	const handleCheckInOpen = async () => {
		if (currentMeasurement === false && isOver30) {
			const isInCoords = await checkSellerPosition(
				form.getFieldValue("latitude"),
				form.getFieldValue("longitude")
			);
			if (isInCoords) {
				setOpenCheckInModal(true);
			} else {
				openNotification(
					"warning",
					"Warning",
					"You must be at job direction to make check in"
				);
			}
		} else {
			if (isOver30) {
				setOpenCheckInModal(true);
			}
		}
	};

	const deleteJob = async () => {
		setLoadingComponents(true);
		const url = api + "/job/" + props.match.params.id;
		const urlLoad = api + "/loadjob/" + props.match.params.id;
		const urlHistory = api + "/history";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		const bodyHistory = {
			date: new Date(),
			customer: form.getFieldValue("customer"),
			user: localStorage.getItem("userId"),
			type: "Job",
			yards: form.getFieldValue("targetYD3"),
			number: form.getFieldValue("number"),
		};

		const req1 = axios.delete(url);
		const req2 = axios.delete(urlLoad);
		const req3 = axios.post(urlHistory, bodyHistory);

		// llamada simultanea a los 2 borrados
		axios
			.all([req1, req2, req3])
			.then(
				axios.spread((...responses) => {
					const res1 = responses[0];
					const res2 = responses[1];
					const res3 = responses[2];
					setLoadingComponents(false);

					props.history.push("/dailyappointment");
				})
			)
			.catch((errors) => {
				openNotification("error", "Error", "Cannot delete job");
				setLoadingComponents(false);
				console.error(errors.message);
				return null;
			});
	};

	const jobPhotos = () => {
		const url = "/jobphotos/" + props.match.params.id;
		props.history.push(url);
	};

	const showHistory = () => {
		const url = "/jobhistory/" + form.getFieldValue("number");
		props.history.push(url);
	};

	const showQuote = () => {
		const url = "/quotepdf/" + props.match.params.id;
		props.history.push(url);
	};

	const showInvoice = async () => {
		await updateJob("invoice");
		const url = "/jobtoinvoice/" + props.match.params.id;
		props.history.push(url);
	};

	const showEtickets = () => {
		const url = "/eticketsjob/" + props.match.params.id;
		props.history.push(url);
	};

	const updateJob = async (fromButton) => {
		const values = form.getFieldsValue(true);

		let updateValues = {
			approved: values.approved,
			customer: values.customer,
			paymentType: values.paymentType,
			seller: values.seller,
			jobStatus: values.jobStatus,
			jobType: values.jobType,
			jobPoor: values.jobPoor,
			name: values.name || undefined,
			jobDescription: values.jobDescription || undefined,
			psi: values.psiName,
			psiId: values.psiId,
			plant: values.plant,
			shipAddress: values.shipAddress,
			shipCity: values.city,
			destinationTime: values.destinationTime,
			distance: values.distance,
			latitude: values.latitude,
			longitude: values.longitude,
			foreman: values.foreman,
			jobForeman: values.jobForeman,
			foremanPhone: "+1" + values.foremanPhone,
			supervisorName: values.supervisorName || undefined,
			supervisorPhone: values.supervisorPhone
				? "+1" + values.supervisorPhone
				: undefined,
			projectManager: values.projectManager || undefined,
			pmPhone: values.pmPhone ? "+1" + values.pmPhone : undefined,
			apContact: values.apContact || undefined,
			apPhone: values.apPhone ? "+1" + values.apPhone : undefined,
			taxExemptJob: values.taxExemptJob,
			targetYD3: values.targetYD3,
			balance: values.balance,
			jobDuration: values.jobDuration,
			logisticDate: values.logisticDate
				? dayjs(values.logisticDate).toDate()
				: undefined,
			scheduledDate: dayjs(values.scheduledDate).toDate(),
			inspectionTimeStamp: dayjs(values.inspectionTimeStamp).toDate(),
			fiberQuantity: values.fiberQuantity || 0,
			unitPrice: values.unitPrice,
			paid: values.paid,
			fuelSurchargeGlobal: values.fuelSurchargeGlobal,
			shippingGlobal: values.shippingGlobal,
			fuelSurcharge: values.fuelSurcharge || 0,
			deliveryFee: values.deliveryFee || 0,
			comments: values.comments || undefined,
			slump: values.slump,
			qualityControl: values.qualityControl,
			updatedBy: localStorage.getItem("userId"),
		};

		if (jobStatusPrev !== updateValues.jobStatus) {
			updateValues.jobStatusHistory = {
				user: localStorage.getItem("userId"),
				jobStatus: updateValues.jobStatus,
			};
		}

		let checkInMadeIt = false;

		if (updateValues.jobStatus === "Ready to Load") {
			if (jobStatusPrev !== updateValues.jobStatus) {
				updateValues.readyToBeScheduledDate = dayjs(
					form.getFieldValue("readyToBeScheduledDate")
				).toDate();
			}

			// if (isOver30) {
			// 	if (
			// 		checkInValues.measurement === false &&
			// 		!(
			// 			localStorage.getItem("email") === "eliud@gmail.com" ||
			// 			localStorage.getItem("email") === "ec@57concrete.com" ||
			// 			localStorage.getItem("email") === "bp@57concrete.com" ||
			// 			localStorage.getItem("email") === "jp@57concrete.com" ||
			// 			localStorage.getItem("email") === "vm@57concrete.com" ||
			// 			localStorage.getItem("email") === "dm@57concrete.com" ||
			// 			localStorage.getItem("email") === "rs@57concrete.com"
			// 		)
			// 	) {
			// 		openNotification(
			// 			"warning",
			// 			"Warning",
			// 			"You must check in to change the status to Ready To Be Scheduled"
			// 		);
			// 		setLoadingComponents(false);
			// 		return;
			// 	} else {
			// 		if (currentMeasurement === false) {
			// 			updateValues.checkInLatitude = checkInLatitude;
			// 			updateValues.checkInLongitude = checkInLongitude;
			// 		}
			// 		updateValues.measurement = checkInValues.measurement;
			// 		updateValues.measurementYards = checkInValues.measurementYards;
			// 		updateValues.readyForTruck = checkInValues.readyForTruck;
			// 		updateValues.readyForTruckComments =
			// 			checkInValues.readyForTruckComments;
			// 		updateValues.lightTowerPositions = checkInValues.lightTowerPositions;
			// 		updateValues.lightTowerPositionsNumberTag =
			// 			checkInValues.lightTowerPositionsNumberTag;
			// 		updateValues.lightTowerPositionsBrand =
			// 			checkInValues.lightTowerPositionsBrand;
			// 		checkInMadeIt = true;
			// 	}
			// }
		} else if (
			updateValues.jobStatus === "ok" ||
			updateValues.jobStatus === "Active"
		) {
			if (isOver30) updateValues.pour = checkInValues.pour;
		}

		if (isOver30) {
			if (
				checkInValues.measurement === false &&
				!(
					localStorage.getItem("email") === "eliud@gmail.com" ||
					localStorage.getItem("email") === "ec@57concrete.com" ||
					localStorage.getItem("email") === "bp@57concrete.com" ||
					localStorage.getItem("email") === "jp@57concrete.com" ||
					localStorage.getItem("email") === "vm@57concrete.com" ||
					localStorage.getItem("email") === "dm@57concrete.com" ||
					localStorage.getItem("email") === "rs@57concrete.com"
				)
			) {
				if (updateValues.jobStatus === "Ready to Load") {
					openNotification(
						"warning",
						"Warning",
						"You must check in to change the status to Ready To Be Scheduled"
					);
					setLoadingComponents(false);
					return;
				}
			} else {
				if (currentMeasurement === false) {
					updateValues.checkInLatitude = checkInLatitude;
					updateValues.checkInLongitude = checkInLongitude;
				}
				updateValues.measurement = checkInValues.measurement;
				updateValues.measurementYards = checkInValues.measurementYards;
				updateValues.readyForTruck = checkInValues.readyForTruck;
				updateValues.readyForTruckComments =
					checkInValues.readyForTruckComments;
				updateValues.lightTowerPositions = checkInValues.lightTowerPositions;
				updateValues.lightTowerPositionsNumberTag =
					checkInValues.lightTowerPositionsNumberTag;
				updateValues.lightTowerPositionsBrand =
					checkInValues.lightTowerPositionsBrand;
				checkInMadeIt = true;
			}
		}

		const url = api + "/job/" + props.match.params.id;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			await axios.patch(url, updateValues);
			setLoadingComponents(false);
			if (dateChanged) {
				sendSMSTextToSeller(updateValues.scheduledDate);
			}
			if (addressChanged) {
				sendEmailToTeam();
			}
			if (isOver30 && checkInMadeIt && currentMeasurement === false) {
				sendEmailToTeamCheckIn();
			}
			if (fromButton === "save") {
				props.history.push("/dailyappointment");
			}
		} catch (err) {
			openNotification("error", "Error", "Cannot get job details");
			setLoadingComponents(false);
			console.error(err.message);
			return null;
		}
	};

	const sendSMSTextToSeller = async (date) => {
		const url = api + "/sellersms";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		let text = `57Concrete: Date of Job No. ${form.getFieldValue(
			"number"
		)} has been moved to ${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
		const body = {
			text: text,
			phone: sellerPhone,
		};

		try {
			await axios.post(url, body);
		} catch (err) {
			console.error(err.message);
		}
	};

	const sendEmailToTeam = async () => {
		const url = api + `/job/address/change/${props.match.params.id}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			await axios.post(url);
		} catch (err) {
			console.error(err.message);
		}
	};

	const sendEmailToTeamCheckIn = async () => {
		const url = api + `/job/checkin/${props.match.params.id}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			await axios.post(url);
		} catch (err) {
			console.error(err.message);
		}
	};

	const checkSellerPosition = async (jobLatitude, jobLongitude) => {
		const url = api + `/job/seller/position`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const body = {
				sellerLatitude: checkInLatitude,
				sellerLongitude: checkInLongitude,
				jobLatitude: jobLatitude,
				jobLongitude: jobLongitude,
			};
			const { data } = await axios.post(url, body);
			if (data.success) {
				return data.payload;
			} else {
				return false;
			}
		} catch (err) {
			console.error(err.message);
			return false;
		}
	};

	const onFinish = async () => {
		setLoadingComponents(true);
		updateJob("save");
	};

	const RenderForm = () => (
		<Form
			form={form}
			layout="vertical"
			initialValues={initialValues}
			onFinish={onFinish}
		>
			<Row gutter={16}>
				<Col span={24}>
					<Form.Item label="Job ID" name="number">
						<Input size="large" disabled />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16} align={"middle"}>
				<Col span={24} md={12}>
					<Form.Item
						label="Customer"
						name="customer"
						rules={[
							{
								required: true,
								message: "Please select a customer",
							},
						]}
					>
						<Select
							style={{
								width: "100%",
							}}
							size="large"
							placeholder="Please select a customer"
							loading={loadingComponents || loadingCustomer}
							options={customers}
							showSearch
							disabled={loadingComponents || loadingCustomer}
							optionFilterProp="children"
							filterOption={filterOption}
						/>
					</Form.Item>
				</Col>
				<Col span={24} md={6} lg={5} xl={4}>
					<Button
						size="large"
						type="primary"
						htmlType="button"
						style={{ width: "100%" }}
						icon={<PlusOutlined />}
						loading={loadingComponents}
						disabled={loadingComponents}
						onClick={handleNewCustomerOpen}
					>
						New Customer
					</Button>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={24} md={12}>
					<Form.Item
						label="Seller"
						name="seller"
						rules={[
							{
								required: true,
								message: "Please select a seller",
							},
						]}
					>
						<Select
							style={{
								width: "100%",
							}}
							size="large"
							placeholder="Please select seller"
							options={sellers}
							disabled={
								loadingComponents ||
								loadingSeller ||
								!(
									localStorage.getItem("role") === "Admin" ||
									localStorage.getItem("email") === "rl@57concrete.com" ||
									localStorage.getItem("email") === "ec@57concrete.com" ||
									localStorage.getItem("email") === "jh@57concrete.com" ||
									localStorage.getItem("email") === "eliud@gmail.com"
								)
							}
							loading={loadingComponents || loadingSeller}
							optionFilterProp="children"
							filterOption={filterOption}
							showSearch
						/>
					</Form.Item>
				</Col>
				<Col span={24} md={12}>
					<Form.Item
						label="Job Status"
						name="jobStatus"
						rules={[
							{
								required: true,
								message: "Please select a status",
							},
						]}
					>
						<Select
							style={{
								width: "100%",
							}}
							size="large"
							placeholder="Please select job status"
							options={jobStatusOptions}
							disabled={
								loadingComponents ||
								!(
									localStorage.getItem("role") === "Admin" ||
									localStorage.getItem("email") === "rl@57concrete.com" ||
									localStorage.getItem("email") === "ec@57concrete.com" ||
									localStorage.getItem("email") === "jh@57concrete.com" ||
									localStorage.getItem("email") === "eliud@gmail.com"
								)
							}
							loading={loadingComponents}
							optionFilterProp="children"
							filterOption={filterOption}
							showSearch
							labelInValue
							onChange={onChangeJobStatus}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="Job Name" name="name">
						<Input size="large" />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Job Description" name="jobDescription">
						<Input size="large" />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={24} md={12}>
					<Form.Item
						label="Job Type"
						name="jobType"
						rules={[
							{
								required: true,
								message: "Please select job type",
							},
						]}
					>
						<Select
							style={{
								width: "100%",
							}}
							size="large"
							placeholder="Please select job type"
							options={jobTypeOptions}
							disabled={loadingComponents}
							loading={loadingComponents}
							optionFilterProp="children"
							filterOption={filterOption}
							showSearch
						/>
					</Form.Item>
				</Col>
				<Col span={24} md={12}>
					<Form.Item
						label="Job Pour"
						name="jobPoor"
						rules={[
							{
								required: true,
								message: "Please select job pour",
							},
						]}
					>
						<Select
							style={{
								width: "100%",
							}}
							size="large"
							placeholder="Please select job pour"
							options={jobPourOptions}
							disabled={loadingComponents}
							loading={loadingComponents}
							optionFilterProp="children"
							filterOption={filterOption}
							showSearch
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={24} md={12}>
					<Form.Item
						label="Psi"
						name="psi"
						rules={[
							{
								required: true,
								message: "Please select a psi",
							},
						]}
					>
						<Select
							style={{
								width: "100%",
							}}
							size="large"
							placeholder="Please select a psi"
							options={psi}
							disabled={loadingComponents || loadingPsi}
							loading={loadingComponents || loadingPsi}
							optionFilterProp="children"
							filterOption={filterOption}
							showSearch
							labelInValue
							onChange={onChangePsi}
						/>
					</Form.Item>
				</Col>
				<Col span={24} md={12}>
					<Form.Item
						label="Plant"
						name="plant"
						rules={[
							{
								required: true,
								message: "Please select a plant",
							},
						]}
					>
						<Select
							style={{
								width: "100%",
							}}
							size="large"
							placeholder="Please select a plant"
							options={plants}
							disabled={loadingComponents || loadingPlant}
							loading={loadingComponents || loadingPlant}
							optionFilterProp="children"
							filterOption={filterOption}
							showSearch
						/>
					</Form.Item>
				</Col>
			</Row>
			<Divider />
			<Title level={3}>Ship to Address</Title>
			<Row gutter={16} align={"middle"}>
				<Col span={24} md={4}>
					<Button
						size="large"
						type="primary"
						htmlType="button"
						icon={<SearchOutlined />}
						style={{ width: "100%" }}
						onClick={handleSearchAddressOpen}
						disabled={loadingComponents}
						loading={loadingComponents}
					>
						Search Address
					</Button>
				</Col>
				<Col span={12} md={10}>
					<Form.Item
						label="Ship Address"
						name="shipAddress"
						rules={[
							{
								required: true,
								message: "Fill ship address",
							},
						]}
					>
						<Input size="large" disabled={loadingComponents} />
					</Form.Item>
				</Col>
				<Col span={12} md={10}>
					<Form.Item
						label="Ship City"
						name="shipCity"
						rules={[
							{
								required: true,
								message: "Please select a city",
							},
						]}
					>
						<Select
							style={{
								width: "100%",
							}}
							size="large"
							placeholder="Please select a city"
							options={cityList}
							disabled={loadingComponents}
							optionFilterProp="children"
							filterOption={filterOption}
							showSearch
							labelInValue
							onChange={onChangeCity}
						/>
					</Form.Item>
				</Col>
				<Col span={24} md={12}>
					<Form.Item label="Time to Destination" name="destinationTime">
						<Input size="large" disabled />
					</Form.Item>
				</Col>
				<Col span={24} md={12}>
					<Form.Item label="Distance" name="distance">
						<Input size="large" disabled />
					</Form.Item>
				</Col>
			</Row>
			<Divider />
			<Row gutter={16} align={"middle"}>
				<Col span={24} md={12}>
					<Form.Item
						label="Foreman"
						name="foremanItem"
						rules={[
							{
								required: true,
								message: "Please select a foreman",
							},
						]}
					>
						<Select
							style={{
								width: "100%",
							}}
							size="large"
							placeholder="Please select foreman"
							options={foremans}
							disabled={loadingComponents}
							loading={loadingForeman}
							optionFilterProp="children"
							filterOption={filterOption}
							showSearch
							onChange={onChangeForeman}
						/>
					</Form.Item>
				</Col>
				<Col span={24} md={6} lg={5} xl={4}>
					<Button
						size="large"
						type="primary"
						htmlType="button"
						icon={<PlusOutlined />}
						style={{ width: "100%" }}
						onClick={handleNewForemanOpen}
						disabled={loadingComponents}
						loading={loadingComponents}
					>
						New Foreman
					</Button>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="Foreman Phone" name="foremanPhone">
						<Input size="large" addonBefore={prefixSelector} disabled />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="Supervisor Name" name="supervisorName">
						<Input size="large" disabled={loadingComponents} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Supervisor Phone"
						name="supervisorPhone"
						rules={[
							{
								len: 10,
								max: 10,
								message: "Phone number must have 10 numbers",
							},
						]}
					>
						<Input
							size="large"
							addonBefore={prefixSelector}
							disabled={loadingComponents}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="Project Manager Name" name="projectManager">
						<Input size="large" disabled={loadingComponents} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Project Manager Phone"
						name="pmPhone"
						rules={[
							{
								len: 10,
								max: 10,
								message: "Phone number must have 10 numbers",
							},
						]}
					>
						<Input
							size="large"
							addonBefore={prefixSelector}
							disabled={loadingComponents}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="AP Contact Name" name="apContact">
						<Input size="large" disabled={loadingComponents} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="AP Contact Phone"
						name="apPhone"
						rules={[
							{
								len: 10,
								max: 10,
								message: "Phone number must have 10 numbers",
							},
						]}
					>
						<Input
							size="large"
							addonBefore={prefixSelector}
							disabled={loadingComponents}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item name="taxExemptJob" label="Tax Exempt Job">
						<Radio.Group
							size="large"
							disabled={loadingComponents}
							buttonStyle="solid"
						>
							<Radio.Button value={true}>Yes</Radio.Button>
							<Radio.Button value={false}>No</Radio.Button>
						</Radio.Group>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Payment Type"
						name="paymentType"
						rules={[
							{
								required: true,
								message: "Please select a payment type",
							},
						]}
					>
						<Select
							style={{
								width: "100%",
							}}
							size="large"
							placeholder="Please select payment type"
							options={paymentTypeOptions}
							disabled={
								loadingComponents || !localStorage.getItem("role") === "Finance"
							}
							optionFilterProp="children"
						/>
					</Form.Item>
				</Col>
			</Row>
			<Divider />
			<Row gutter={16}>
				<Col span={8}>
					<Form.Item
						label="Target YD3"
						name="targetYD3"
						rules={[
							{
								required: true,
								message: "Please fill target",
							},
						]}
					>
						<InputNumber
							size="large"
							style={{ width: "100%" }}
							formatter={(value) =>
								`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							}
							parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							disabled={loadingComponents}
						/>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Balance" name="balance">
						<InputNumber
							size="large"
							style={{ width: "100%" }}
							formatter={(value) =>
								`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							}
							parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							disabled={loadingComponents}
						/>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Job Duration" name="jobDuration">
						<Input size="large" disabled={loadingComponents} />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={24} md={12}>
					<Form.Item
						name="scheduledDate"
						label="Job date/time"
						rules={[
							{
								required: true,
								message: "Scheduled Date is required",
							},
						]}
					>
						<DatePicker
							style={{ width: "100%" }}
							size="large"
							showTime={{ format: "HH:mm" }}
							format="MM/DD/YYYY HH:mm A"
							allowClear={false}
							onChange={onChangeDate}
							disabled={loadingComponents || disabledDate}
							minDate={minDateScheduledDate}
						/>
					</Form.Item>
				</Col>
				<Col span={24} md={12}>
					<Form.Item
						name="inspectionTimeStamp"
						label="Inspection date/time"
						rules={[
							{
								required: true,
								message: "Inspection Time Stamp is required",
							},
						]}
					>
						<DatePicker
							style={{ width: "100%" }}
							size="large"
							showTime={{ format: "HH:mm" }}
							format="MM/DD/YYYY HH:mm A"
							allowClear={false}
							maxDate={dateMax}
							disabledTime={disabledDateTime}
							disabled={loadingComponents}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={24} md={12}>
					<Form.Item label="Fiber Quantity" name="fiberQuantity">
						<InputNumber
							size="large"
							style={{ width: "100%" }}
							formatter={(value) =>
								`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							}
							parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							disabled={loadingComponents}
						/>
					</Form.Item>
				</Col>
				<Col span={24} md={12}>
					<Form.Item label="Paid" name="paid" valuePropName="checked">
						<Checkbox>Is Paid?</Checkbox>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={24} md={12}>
					<Form.Item
						label="Unit Price"
						name="unitPrice"
						rules={[
							{
								required: true,
								message: "Unit Price is required",
							},
						]}
					>
						<InputNumber
							size="large"
							style={{ width: "100%" }}
							formatter={(value) =>
								`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							}
							parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							disabled={loadingComponents}
							onChange={onChangeUnitPrice}
							precision={3}
						/>
					</Form.Item>
				</Col>

				<Col span={24} md={12}>
					<Form.Item
						label="Price with Tax"
						name="taxPrice"
						rules={[
							{
								required: true,
								message: "Price with Tax is required",
							},
						]}
					>
						<InputNumber
							size="large"
							style={{ width: "100%" }}
							formatter={(value) =>
								`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							}
							parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							disabled
							precision={3}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={4}>
					<Form.Item name="fuelSurchargeGlobal" valuePropName="checked">
						<Checkbox disabled={loadingComponents}>Apply To Global</Checkbox>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Fuel Surcharge" name="fuelSurcharge">
						<InputNumber
							size="large"
							style={{ width: "100%" }}
							formatter={(value) =>
								`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							}
							parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							disabled={loadingComponents}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={4}>
					<Form.Item name="shippingGlobal" valuePropName="checked">
						<Checkbox disabled={loadingComponents}>Apply To Global</Checkbox>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Shipping Fee" name="deliveryFee">
						<InputNumber
							size="large"
							style={{ width: "100%" }}
							formatter={(value) =>
								`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							}
							parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							disabled={loadingComponents}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={24}>
					<Form.Item label="Comments" name="comments">
						<Input size="large" disabled={loadingComponents} />
					</Form.Item>
				</Col>
				<Col span={24} md={12}>
					<Form.Item
						label="Slump"
						name="slump"
						rules={[
							{
								required: true,
								message: "Please select a slump",
							},
						]}
					>
						<Select
							style={{
								width: "100%",
							}}
							size="large"
							placeholder="Please select slump"
							options={slumpOptions}
							optionFilterProp="children"
							disabled={loadingComponents}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Quality Control"
						name="qualityControl"
						valuePropName="checked"
					>
						<Checkbox disabled={loadingComponents}>QC Inspection</Checkbox>
					</Form.Item>
				</Col>
			</Row>
			<Divider />
			<Title level={3}>Job Status History</Title>
			<CustomTable columns={columns} data={jobStatusHistory} isScroll={false} />
			<Row gutter={[16, 8]}>
				<Col>
					<Button
						type="primary"
						htmlType="submit"
						size="large"
						loading={loadingComponents}
						disabled={loadingComponents}
					>
						Save
					</Button>
				</Col>
				{(localStorage.getItem("email") === "ec@57concrete.com" ||
					localStorage.getItem("email") === "eliud@gmail.com") && (
					<Col>
						<Popconfirm
							title="Delete the job"
							description="Are you sure to delete this job?"
							okText="Yes"
							cancelText="No"
							onConfirm={deleteJob}
						>
							<Button
								type="primary"
								danger
								htmlType="button"
								size="large"
								loading={loadingComponents}
								disabled={loadingComponents}
							>
								Delete
							</Button>
						</Popconfirm>
					</Col>
				)}
				{(localStorage.getItem("role") === "Admin" ||
					localStorage.getItem("role") === "Seller" ||
					localStorage.getItem("email") === "eliud@gmail.com") && (
					<Col>
						<Button
							type="primary"
							style={{ backgroundColor: "gray" }}
							htmlType="button"
							size="large"
							loading={loadingComponents}
							disabled={loadingComponents}
							onClick={jobPhotos}
						>
							Photos
						</Button>
					</Col>
				)}
				{(localStorage.getItem("role") === "Admin" ||
					localStorage.getItem("role") === "Finance") && (
					<Col>
						<Button
							type="primary"
							style={{ backgroundColor: "purple" }}
							htmlType="button"
							size="large"
							loading={loadingComponents}
							disabled={loadingComponents}
							onClick={showHistory}
						>
							Change Log
						</Button>
					</Col>
				)}
				<Col>
					<Button
						type="primary"
						style={{ backgroundColor: "green" }}
						htmlType="button"
						size="large"
						loading={loadingComponents}
						disabled={loadingComponents}
						onClick={showQuote}
					>
						Quote PDF
					</Button>
				</Col>
				{jobStatusCurrent === "Operations done (AR)" && (
					<Col>
						<Button
							type="primary"
							style={{ backgroundColor: "blue" }}
							htmlType="button"
							size="large"
							loading={loadingComponents}
							disabled={loadingComponents}
							onClick={showInvoice}
						>
							Invoice
						</Button>
					</Col>
				)}
				{loads.length !== 0 && (
					<Col>
						<Button
							type="primary"
							htmlType="button"
							size="large"
							loading={loadingComponents}
							disabled={loadingComponents}
							onClick={showEtickets}
						>
							Etickets
						</Button>
					</Col>
				)}
				<Col>
					<Button
						type="default"
						htmlType="button"
						size="large"
						loading={loadingComponents}
						disabled={loadingComponents}
						onClick={handleShowLoadsOpen}
					>
						Loads
					</Button>
				</Col>
				{isOver30 && (
					<Col>
						<Button
							type="primary"
							htmlType="button"
							size="large"
							loading={loadingComponents}
							disabled={loadingComponents}
							onClick={handleCheckInOpen}
						>
							Job Check In
						</Button>
					</Col>
				)}
			</Row>
		</Form>
	);

	return (
		<Layout title="Job" description="Edit Job">
			<Flex vertical gap={"large"}>
				{contextHolder}
				{loading ? <CustomSpinner /> : <RenderForm />}
				<RenderNewCustomerModal />
				<RenderSearchAddressModal />
				<RenderNewForemanModal />
				<RenderShowLoadsModal />
				<RenderCheckInModal />
			</Flex>
		</Layout>
	);
};

export default Job2;
