import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toCurrency } from "../utils/Convert";
import TaxPicker from "./TaxPicker";
import LoadList from "../load/LoadList";
import { api } from "../utils/Api";
import InvoicePicker from "./InvoicePicker";
import DiscountPicker from "./DiscountPicker";
import SpinnerButton from "../layout/SpinnerButton";

const JobToInvoiceModal = ({ jobId, pcb, history }) => {
	const [customer, setCustomer] = useState("");
	const [customerName, setCustomerName] = useState("");
	const [job, setJob] = useState("");
	const [invoiceAddress, setInvoiceAddress] = useState("");
	const [stateTax, setStateTax] = useState(0);
	const [countyTax, setCountyTax] = useState(0);
	const [taxAmount, setTaxAmount] = useState(0);
	const [invoiceTax, setInvoiceTax] = useState({
		label: "State",
		value: "state",
	});
	const [invoiceDate, setInvoiceDate] = useState("");
	const [unitPrice, setUnitPrice] = useState(0);
	const [paymentTerm, setPaymentTerm] = useState("7");
	const [psi, setPsi] = useState("");
	const [psiDescription, setPsiDescription] = useState("");
	const [quantity, setQuantity] = useState(0);
	const [loadList, setLoadList] = useState([]);
	const [amount, setAmount] = useState(0);
	const [fuelSurcharge, setFuelSurcharge] = useState(true);
	const [fuelRate, setFuelRate] = useState(25);
	const [trips, setTrips] = useState(0);
	const [isCreditCardPayment, setIsCreditCardPayment] = useState(false);
	const [creditCardFee, setCreditCardFee] = useState(4);
	const [factoring, setFactoring] = useState(false);
	const [applyDiscount, setApplyDiscount] = useState(false);
	const [discount, setDiscount] = useState(0);
	const [discountAmount, setDiscountAmount] = useState(0);
	const [discountReason, setDiscountReason] = useState("");
	const [po, setPO] = useState("");
	const [invoiceNumber, setInvoiceNumber] = useState("");
	const [invoiceTotal, setInvoiceTotal] = useState(0);
	const [invoiceExists, setInvoiceExists] = useState(false);
	const [invoiceID, setInvoiceID] = useState("");
	const [shipping, setShipping] = useState(true);
	const [shippingRate, setShippingRate] = useState(0);
	const [color, setColor] = useState(false);
	const [colorTicket, setColorTicket] = useState(0);
	const [fiber, setFiber] = useState(false);
	const [fiberBags, setFiberBags] = useState(0);
	const [size, setSize] = useState(false);
	const [sizeBags, setSizeBags] = useState(0);
	const [miscelaneos, setMiscelaneos] = useState(false);
	const [miscelaneosFee, setMiscelaneosFee] = useState(0);
	const [miscelaneosReason, setMiscelaneosReason] = useState("");
	const [invoiceType, setInvoiceType] = useState({
		value: "total",
		label: "Total",
	});
	const [discountType, setDiscountType] = useState({
		value: "percentageAfter",
		label: "Percentage (%) After Taxes",
	});
	const [subtotal, setSubtotal] = useState(0);
	const [creditCardCost, setCreditCardCost] = useState(0);
	const [isTotal, setIsTotal] = useState(true);
	const [productList, setProductList] = useState([]);
	const [fuelSurchargeGlobal, setFuelSurchargeGlobal] = useState(false);
	const [shippingGlobal, setShippingGlobal] = useState(true);
	const [colorGlobal, setColorGlobal] = useState(false);
	const [fiberGlobal, setFiberGlobal] = useState(false);
	const [sizeGlobal, setSizeGlobal] = useState(false);
	const [miscelaneosGlobal, setMiscelaneosGlobal] = useState(false);

	const shipRef = useRef(0);
	const fuelRef = useRef(25);
	const discountRef = useRef(0);
	const creditRef = useRef(4);
	const colorRef = useRef(0);
	const fiberRef = useRef(0);
	const sizeRef = useRef(0);
	const miscelaneosRef = useRef(0);
	const totalsPerTicket = useRef({});
	const loading = useRef(true);

	useEffect(() => {
		getCompanyInfo();
		getInvoiceExistance();
		getJobInfo();
		loading.current = false;
	}, []);

	useEffect(() => {
		getInvoiceInfo();
		loading.current = false;
	}, [invoiceID, invoiceExists]);

	/**
	 * Check if the invoice exists
	 */
	const getInvoiceExistance = async () => {
		const url = api + "/jobinvoice/" + jobId;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			setInvoiceID(data._id);
			setInvoiceExists(true);
		} catch (err) {
			setInvoiceExists(false);
			return null;
		}
	};

	/**
	 * Get company information
	 */
	const getCompanyInfo = async () => {
		const url = api + "/company";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			setStateTax(data.stateTax);
			setCountyTax(data.countyTax);
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	/**
	 * Get job information
	 */
	const getJobInfo = async () => {
		const url = api + "/job/" + jobId;
		const urlPsiList = api + "/psi/";
		const urlLoad = api + "/load/" + jobId;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		const reqJob = await axios.get(url);
		const reqList = await axios.get(urlPsiList);
		const reqLoad = await axios.get(urlLoad);

		axios
			.all([reqJob, reqList, reqLoad])
			.then(
				axios.spread((...responses) => {
					const job = responses[0].data;
					const resList = responses[1].data;
					const loadList = responses[2].data;
					setTrips(loadList.length);

					// Product description
					const found = resList.find((item) => item.psi === job.psi);
					const description = found ? found.description : job.psi;
					setPsiDescription(description);
					setProductList(resList);

					// Customer id and name
					setCustomer(job.customer._id);
					setCustomerName(
						job.customer.customerNumber + " " + job.customer.name
					);

					// Job attributes
					setJob(job._id);
					setInvoiceDate(job.scheduledDate);
					setUnitPrice(job.unitPrice);
					setPsi(job.psi);
					setPO(job.po);
					setInvoiceAddress(job.shipAddress);
					setFuelRate(job.fuelSurcharge);
					setShippingRate(job.deliveryFee ? job.deliveryFee : 0);
					setFuelSurchargeGlobal(job.fuelSurchargeGlobal);
					setShippingGlobal(job.shippingGlobal);

					shipRef.current = job.deliveryFee;
					fuelRef.current = job.fuelSurcharge;

					// Obtiene el numero de folio de la factura
					setLoadList(loadList);
					setInvoiceNumber(`2${job.number}`); // 2 + job._id

					// calculator
					const totalLoad = loadList.reduce((acc, load) => acc + load.yards, 0);
					setQuantity(totalLoad);
					const materialAmount = totalLoad * job.unitPrice;
					setAmount(materialAmount);
				})
			)
			.catch((errors) => {
				console.error(errors.message);
				return null;
			});
	};

	/**
	 * Post job
	 */
	const postJob = async () => {
		const job = {
			shipAddress: invoiceAddress,
			fuelSurcharge: fuelRate,
			deliveryFee: shippingRate,
			fuelSurchargeGlobal: fuelSurchargeGlobal,
			shippingGlobal: shippingGlobal,
			psi: psi,
			updatedBy: localStorage.getItem("userId"),
		};

		const url = api + "/job/" + jobId;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			await axios.patch(url, job);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	/**
	 * Get invoice information
	 */
	const getInvoiceInfo = async () => {
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		if (invoiceExists) {
			try {
				const urlGet = api + `/invoice/${invoiceID}`;
				const { data } = await axios.get(urlGet);
				setColor(data.color);
				setColorTicket(data.colorTicket);
				setFiber(data.fiber);
				setFiberBags(data.fiberBags);
				setSize(data.size);
				setSizeBags(data.sizeBags);
				setFuelSurcharge(data.fuelSurcharge);
				setShipping(data.shipping);
				setCreditCardFee(data.creditCardFee);
				setIsCreditCardPayment(data.isCreditCardPayment);
				setMiscelaneos(data.miscelaneos);
				setMiscelaneosFee(data.miscelaneosFee);
				setMiscelaneosReason(data.miscelaneosReason);
				setApplyDiscount(data.isDiscount);
				setDiscountAmount(data.discountAmount);
				setDiscountReason(data.discountReason);
				setPaymentTerm(data.paymentTerm);
				setDiscount(data.discount === 0 ? data.discountAmount : data.discount);
				setDiscountType(
					data.discount !== 0
						? { value: "percentageAfter", label: "Percentage (%) After Taxes" }
						: { value: "amountAfter", label: "Amount ($) After Taxes" }
				);
				setIsTotal(data.isTotal);
				setInvoiceType(
					data.isTotal
						? { value: "total", label: "Total" }
						: { value: "tickets", label: "Per Tickets" }
				);
				setFactoring(data.factoring);
				setColorGlobal(data.colorGlobal);
				setFiberGlobal(data.fiberGlobal);
				setSizeGlobal(data.sizeGlobal);
				setMiscelaneosGlobal(data.miscelaneosGlobal);

				discountRef.current = data.discount;
				creditRef.current = data.creditCardFee;
				colorRef.current = data.colorTicket;
				fiberRef.current = data.fiberBags;
				sizeRef.current = data.sizeBags;
				miscelaneosRef.current = data.miscelaneosFee;
			} catch (err) {
				console.error(err);
				return null;
			}
		}
	};

	/**
	 * Post load
	 * @param {Object} ticket current delivery ticket
	 */
	const postLoad = async (ticket) => {
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		const load = {
			fuelSurcharge: ticket.fuelSurcharge,
			fuelRate: ticket.fuelRate,
			shipping: ticket.shipping,
			shippingFee: ticket.shippingFee,
			color: ticket.color,
			colorTicket: ticket.colorTicket,
			fiber: ticket.fiber,
			fiberBags: ticket.fiberBags,
			size: ticket.size,
			sizeBags: ticket.sizeBags,
			miscelaneos: ticket.miscelaneos,
			miscelaneosFee: ticket.miscelaneosFee,
			miscelaneos: ticket.miscelaneos,
			miscelaneosReason: ticket.miscelaneosReason
				? ticket.miscelaneosReason
				: "",
		};

		try {
			const urlPatch = api + `/load/${ticket._id}`;
			await axios.patch(urlPatch, load);
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	/**
	 * Save changes of each load list
	 */
	const onSaveLoadsChanges = () => {
		loadList.map((load) => {
			postLoad(load);
		});
	};

	/**
	 * Change quantity of yards
	 * @param {Event} e event of the input
	 */
	const changeQuantity = (e) => {
		const totalLoad = e.target.value;
		setQuantity(totalLoad);
		setAmount(totalLoad * unitPrice);
		invoiceType.value === "total"
			? calculateTax(invoiceTax.value)
			: calculateTotals();
	};

	/**
	 * Change tax type
	 * @param {Object} selectedTax current selected tax
	 */
	const onChangeTax = (selectedTax) => {
		const impuesto = selectedTax.value;
		setInvoiceTax(selectedTax);
		invoiceType.value === "total" ? calculateTax(impuesto) : calculateTotals();
	};

	/**
	 * Change invoice type
	 * @param {Object} selectedInvoice current selected invoice
	 */
	const onChangeInvoice = (selectedInvoice) => {
		const invoice = selectedInvoice.value;
		setInvoiceType(selectedInvoice);
		setIsTotal((prevValue) => !prevValue);

		if (invoice !== "total") {
			fuelRef.current = 0;
			shipRef.current = 0;
			setCreditCardCost(0);
			colorRef.current = 0;
			fiberRef.current = 0;
			sizeRef.current = 0;
			miscelaneosRef.current = 0;
			calculateTotals();
		} else {
			fuelRef.current = fuelRate;
			shipRef.current = shippingRate;
			colorRef.current = colorTicket;
			fiberRef.current = fiberBags;
			sizeRef.current = sizeBags;
			miscelaneosRef.current = miscelaneosFee;
			calculateTax(invoiceTax.value, discountType.value, invoice);
		}
	};

	/**
	 * Change discount type
	 * @param {Object} selectedDiscount current selected invoice
	 */
	const onChangeDiscount = (selectedDiscount) => {
		const type = selectedDiscount.value;
		setDiscountType(selectedDiscount);
		invoiceType.value === "total"
			? calculateTax(invoiceTax.value, type)
			: calculateTotals();
	};

	/**
	 * This event occurs only when at least one of the values inside []
	 */
	useEffect(() => {
		if (invoiceType.value === "total") {
			calculateTax(invoiceTax.value);
		} else {
			calculateTotals();
		}
	}, [
		fuelSurcharge,
		shipping,
		isCreditCardPayment,
		discount,
		color,
		fiber,
		size,
		miscelaneos,
		applyDiscount,
		amount,
		fuelSurchargeGlobal,
		shippingGlobal,
		colorGlobal,
		fiberGlobal,
		sizeGlobal,
		miscelaneosGlobal,
	]);

	/**
	 * Calculate totals for invoice type 'Per Tickets'
	 */
	const calculateTotals = () => {
		let tripCharge = 0;
		let shippingFee = 0;
		let colorFee = 0;
		let fiberFee = 0;
		let sizeFee = 0;
		let miscelaneosFee = 0;

		loadList.map((load) => {
			if (load.fuelSurcharge) tripCharge += load.fuelRate;
			if (load.shipping) shippingFee += load.shippingFee;
			if (load.color) colorFee += load.colorTicket * 50;
			if (load.fiber) fiberFee += load.fiberBags * 20;
			if (load.size) sizeFee += load.sizeBags * 20;
			if (load.miscelaneos) miscelaneosFee += load.miscelaneosFee;
		});

		totalsPerTicket.current = {
			tripCharge: tripCharge,
			shippingFee: shippingFee,
			colorFee: colorFee,
			fiberFee: fiberFee,
			sizeFee: sizeFee,
			miscelaneosFee: miscelaneosFee,
		};
		calculateTax();
	};

	/**
	 * Tax and total calculator
	 * @param {Object} impuesto current tax type selected
	 * @param {Object} type current discount type selected
	 * @param {Object} invoice current invoice type selected
	 */
	const calculateTax = (
		impuesto = invoiceTax.value,
		type = discountType.value,
		invoice = invoiceType.value
	) => {
		let taxPercentaje = 0;
		switch (impuesto) {
			case "state":
				taxPercentaje = stateTax;
				break;
			case "county":
				taxPercentaje = countyTax;
				break;
			case "exempt":
				taxPercentaje = 0;
				break;
			default:
				taxPercentaje = stateTax;
		}

		let subtotal = amount;

		if (invoice === "total") {
			if (fuelSurcharge) {
				if (fuelSurchargeGlobal) {
					subtotal += parseFloat(fuelRef.current);
				} else {
					subtotal += parseFloat(fuelRef.current * trips);
				}
			}
			if (shipping) {
				if (shippingGlobal) {
					subtotal += parseFloat(shipRef.current);
				} else {
					subtotal += parseFloat(shipRef.current * trips);
				}
			}
			if (color) {
				if (colorGlobal) {
					subtotal += parseFloat(colorRef.current);
				} else {
					subtotal += parseFloat(colorRef.current * trips);
				}
			}
			if (fiber) {
				if (fiberGlobal) {
					subtotal += parseFloat(fiberRef.current);
				} else {
					subtotal += parseFloat(fiberRef.current * trips);
				}
			}
			if (size) {
				if (sizeGlobal) {
					subtotal += parseFloat(sizeRef.current);
				} else {
					subtotal += parseFloat(sizeRef.current * trips);
				}
			}
			if (miscelaneos) {
				if (miscelaneosGlobal) {
					subtotal += parseFloat(miscelaneosRef.current);
				} else {
					subtotal += parseFloat(miscelaneosRef.current * trips);
				}
			}
			if (isCreditCardPayment) {
				let tarjeta =
					parseFloat(subtotal) * (parseFloat(creditRef.current) / 100.0);
				subtotal += parseFloat(tarjeta);
				setCreditCardCost(tarjeta);
			}
		} else {
			subtotal += totalsPerTicket.current.tripCharge;
			subtotal += totalsPerTicket.current.shippingFee;
			subtotal += totalsPerTicket.current.colorFee;
			subtotal += totalsPerTicket.current.fiberFee;
			subtotal += totalsPerTicket.current.sizeFee;
			subtotal += totalsPerTicket.current.miscelaneosFee;
		}

		setSubtotal(subtotal);

		let taxes = 0;
		let suma = 0;

		if (applyDiscount) {
			if (type === "percentageAfter") {
				taxes = subtotal * taxPercentaje;
				suma = subtotal + taxes;
				let descuento =
					parseFloat(suma) * (parseFloat(discountRef.current) / 100.0);
				suma -= descuento;
				setDiscountAmount(descuento);
			} else if (type === "percentageBefore") {
				let descuento =
					parseFloat(subtotal) * (parseFloat(discountRef.current) / 100.0);
				taxes = subtotal * taxPercentaje;
				suma = subtotal - descuento + taxes;
				setDiscountAmount(descuento);
			} else if (type === "amountAfter") {
				taxes = subtotal * taxPercentaje;
				suma = subtotal + taxes;
				let descuento = discountRef.current;
				suma -= descuento;
				setDiscountAmount(descuento);
			} else if (type === "amountBefore") {
				let descuento = discountRef.current;
				suma = subtotal - descuento;
				taxes = suma * taxPercentaje;
				suma += taxes;
				setDiscountAmount(descuento);
			}
		} else {
			setDiscountAmount(0);
			setDiscount(0);
			taxes = subtotal * taxPercentaje;
			suma = subtotal + taxes;
		}

		setTaxAmount(taxes);
		setInvoiceTotal(suma);
	};

	/**
	 * Post new invoice
	 */
	const postInvoice = async () => {
		const url = api + "/invoice";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const invoice = {
			customer,
			job,
			psi,
			psiDescription,
			invoiceNumber,
			invoiceCustomerName: customerName,
			invoiceAddress,
			invoiceTax: invoiceTax.value,
			invoiceDate,
			paymentTerm,
			quantity,
			rate: unitPrice,
			amount,
			taxAmount,
			fuelSurcharge,
			fuelRate: !fuelSurcharge ? 0 : fuelRate,
			isCreditCardPayment,
			creditCardFee: !isCreditCardPayment ? 0 : creditCardFee,
			creditCardCost: !isCreditCardPayment ? 0 : creditCardCost,
			factoring,
			isDiscount: applyDiscount,
			discount: !applyDiscount ? 0 : discountRef.current,
			discountReason: !applyDiscount ? "" : discountReason,
			discountAmount: !applyDiscount ? 0 : discountAmount,
			invoiceTotal,
			shipping,
			shippingFee: !shipping ? 0 : shippingRate,
			color,
			colorTicket: !color ? 0 : colorTicket,
			fiber,
			fiberBags: !fiber ? 0 : fiberBags,
			size,
			sizeBags: !size ? 0 : sizeBags,
			miscelaneos,
			miscelaneosFee: !miscelaneos ? 0 : miscelaneosFee,
			miscelaneosReason: !miscelaneos ? "" : miscelaneosReason,
			isTotal,
			fuelSurchargeGlobal,
			shippingGlobal,
			colorGlobal,
			fiberGlobal,
			sizeGlobal,
			miscelaneosGlobal,
		};

		if (invoiceExists) {
			// Delete values not allowed to update
			delete invoice.customer;
			delete invoice.job;
			delete invoice.invoiceNumber;
			delete invoice.invoiceCustomerName;
			delete invoice.invoiceDate;

			try {
				const urlPatch = api + `/invoice/${invoiceID}`;
				await axios.patch(urlPatch, invoice);
			} catch (err) {
				console.error(err);
				return null;
			}
		} else {
			// No invoice has been created
			try {
				await axios.post(url, invoice);
			} catch (err) {
				console.error(err.message);
				return null;
			}
		}
	};

	/**
	 * Save changes of the inputs
	 * @param {Event} e event of the input
	 */
	const submit = (e) => {
		e.preventDefault();
		loading.current = true;
		postInvoice();
		postJob();
		if (invoiceType.value === "tickets") onSaveLoadsChanges();
		loading.current = false;
		pcb();
	};

	const InvoiceForm = () => (
		<form className="form" onSubmit={submit}>
			<div className="form-group row">
				<div className="col-sm-4">
					<label className="text-muted">Ship Date</label>
					<input
						type="text"
						className="form-control"
						value={new Date(invoiceDate).toLocaleDateString()}
						readOnly
					/>
				</div>
				<div className="col-sm-4">
					<label className="text-muted">Tax</label>
					<TaxPicker action={onChangeTax} value={invoiceTax} />
				</div>
				<div className="col-sm-4">
					<label className="text-muted">Rate</label>
					<input
						type="text"
						className="form-control"
						style={{ textAlign: "right" }}
						value={toCurrency(unitPrice, 2)}
						readOnly
					/>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Quantity</label>
					<input
						type="number"
						className="form-control"
						style={{ textAlign: "right" }}
						value={quantity}
						onChange={changeQuantity}
					/>
				</div>
				<div className="col-sm-6">
					<label className="text-muted">Material</label>
					<input
						style={{ textAlign: "right" }}
						type="text"
						className="form-control"
						value={toCurrency(amount, 2)}
						readOnly
					/>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Invoice Type</label>
					<InvoicePicker action={onChangeInvoice} value={invoiceType} />
				</div>
			</div>
			<div
				className="form-group row"
				hidden={invoiceType.value === "tickets" ? true : false}
			>
				<div className="col-sm-6">
					<input
						name="fuelSurcharge"
						type="checkbox"
						checked={fuelSurcharge}
						onChange={(e) => setFuelSurcharge((prevValue) => !prevValue)}
					/>
					<label>&nbsp;Standar Fuel Surcharge</label>
				</div>
			</div>
			{fuelSurcharge && (
				<div
					className="form-group row"
					hidden={invoiceType.value === "tickets" ? true : false}
				>
					<div className="col-sm-4 d-flex justify-content-center">
						<input
							name="fuelSurchargeGlobal"
							type="checkbox"
							checked={fuelSurchargeGlobal}
							onChange={(e) =>
								setFuelSurchargeGlobal((prevValue) => !prevValue)
							}
						/>
						<label className="mt-auto mb-auto">&nbsp;Apply To Global</label>
					</div>
					<div className="col-sm-4">
						<label className="text-muted">Fee</label>
						<input
							name="FuelRate"
							type="number"
							className="form-control"
							value={fuelRate}
							onChange={(e) => {
								setFuelRate(e.target.value);
								fuelRef.current = e.target.value ? e.target.value : 0;
								calculateTax();
							}}
						/>
					</div>
					<div className="col-sm-4">
						<label className="text-muted">Trip charge</label>
						<input
							name="tripTotal"
							style={{ textAlign: "right" }}
							className="form-control"
							readOnly
							value={toCurrency(trips * fuelRate, 2)}
						/>
					</div>
				</div>
			)}
			<div
				className="form-group row"
				hidden={invoiceType.value === "tickets" ? true : false}
			>
				<div className="col-sm-6">
					<input
						name="shipping"
						type="checkbox"
						checked={shipping}
						onChange={(e) => setShipping((prevValue) => !prevValue)}
					/>
					<label>&nbsp;Shipping Fee</label>
				</div>
			</div>
			{shipping && (
				<div
					className="form-group row"
					hidden={invoiceType.value === "tickets" ? true : false}
				>
					<div className="col-sm-4 d-flex justify-content-center">
						<input
							name="shippingGlobal"
							type="checkbox"
							checked={shippingGlobal}
							onChange={(e) => setShippingGlobal((prevValue) => !prevValue)}
						/>
						<label className="mt-auto mb-auto">&nbsp;Apply To Global</label>
					</div>
					<div className="col-sm-8">
						<label className="text-muted">Fee</label>
						<input
							name="shipping"
							type="number"
							className="form-control"
							value={shippingRate}
							onChange={(e) => {
								setShippingRate(e.target.value);
								shipRef.current = e.target.value ? e.target.value : 0;
								calculateTax();
							}}
						/>
					</div>
				</div>
			)}
			<div
				className="form-group row"
				hidden={invoiceType.value === "tickets" ? true : false}
			>
				<div className="col-sm-6">
					<input
						name="cardPayment"
						type="checkbox"
						checked={isCreditCardPayment}
						onChange={(e) => {
							setIsCreditCardPayment((prevValue) => !prevValue);
						}}
					/>
					<label>&nbsp;Credit Card Payment</label>
				</div>
				{isCreditCardPayment && (
					<div className="col-sm-6">
						<label className="text-muted">Credit Card Fee (%)</label>
						<input
							name="CreditCardFee"
							type="number"
							className="form-control"
							value={creditCardFee}
							onChange={(e) => {
								setCreditCardFee(e.target.value);
								creditRef.current = e.target.value ? e.target.value : 0;
								calculateTax();
							}}
						/>
					</div>
				)}
			</div>
			<div className="form-group">
				<input
					name="applyDiscount"
					type="checkbox"
					checked={applyDiscount}
					onChange={(e) => {
						setApplyDiscount((prevValue) => !prevValue);
						invoiceType.value === "total" ? calculateTax() : calculateTotals();
					}}
				/>
				<label>&nbsp;Apply Discount</label>
			</div>
			{applyDiscount && (
				<div className="form-group row">
					<div className="col-sm-5">
						<label className="text-muted">Discount Type</label>
						<DiscountPicker action={onChangeDiscount} value={discountType} />
					</div>
					<div className="col-sm-3">
						<label className="text-muted">Discount</label>
						<input
							name="discount"
							type="number"
							className="form-control"
							value={discount}
							onChange={(e) => {
								setDiscount(e.target.value);
								discountRef.current = e.target.value ? e.target.value : 0;
								calculateTax();
							}}
						/>
					</div>
					<div className="col-sm-4">
						<label className="text-muted">Discount Reason</label>
						<input
							name="discountReason"
							type="text"
							className="form-control"
							value={discountReason}
							onChange={(e) => setDiscountReason(e.target.value)}
						/>
					</div>
				</div>
			)}
			<div
				className="form-group"
				hidden={invoiceType.value === "tickets" ? true : false}
			>
				<input
					name="factoring"
					type="checkbox"
					checked={factoring}
					onChange={(e) => setFactoring(!factoring)}
				/>
				<label>&nbsp;Requiere Factoring</label>
			</div>
			<div
				className="form-group row"
				hidden={invoiceType.value === "tickets" ? true : false}
			>
				<div className="col-sm-6">
					<input
						name="color"
						type="checkbox"
						checked={color}
						onChange={(e) => {
							setColor((prevValue) => !prevValue);
						}}
					/>
					<label>&nbsp;Color</label>
				</div>
			</div>
			{color && (
				<div
					className="form-group row"
					hidden={invoiceType.value === "tickets" ? true : false}
				>
					<div className="col-sm-4 d-flex justify-content-center">
						<input
							name="colorGlobal"
							type="checkbox"
							checked={colorGlobal}
							onChange={(e) => setColorGlobal((prevValue) => !prevValue)}
						/>
						<label className="mt-auto mb-auto">&nbsp;Apply To Global</label>
					</div>
					<div className="col-sm-8">
						<label className="text-muted">Fee</label>
						<input
							name="Color"
							type="number"
							className="form-control"
							value={colorTicket}
							onChange={(e) => {
								setColorTicket(e.target.value);
								colorRef.current = e.target.value ? e.target.value : 0;
								calculateTax();
							}}
						/>
					</div>
				</div>
			)}

			<div
				className="form-group row"
				hidden={invoiceType.value === "tickets" ? true : false}
			>
				<div className="col-sm-6">
					<input
						name="fiber"
						type="checkbox"
						checked={fiber}
						onChange={() => setFiber((prevValue) => !prevValue)}
					/>
					<label>&nbsp;Fiber</label>
				</div>
			</div>
			{fiber && (
				<div
					className="form-group row"
					hidden={invoiceType.value === "tickets" ? true : false}
				>
					<div className="col-sm-4 d-flex justify-content-center">
						<input
							name="fiberGlobal"
							type="checkbox"
							checked={fiberGlobal}
							onChange={(e) => setFiberGlobal((prevValue) => !prevValue)}
						/>
						<label className="mt-auto mb-auto">&nbsp;Apply To Global</label>
					</div>
					<div className="col-sm-8">
						<label className="text-muted">Fee</label>
						<input
							name="Fiber"
							type="number"
							className="form-control"
							value={fiberBags}
							onChange={(e) => {
								setFiberBags(e.target.value);
								fiberRef.current = e.target.value ? e.target.value : 0;
								calculateTax();
							}}
						/>
					</div>
				</div>
			)}

			<div
				className="form-group row"
				hidden={invoiceType.value === "tickets" ? true : false}
			>
				<div className="col-sm-6">
					<input
						name="size"
						type="checkbox"
						checked={size}
						onChange={() => setSize((prevValue) => !prevValue)}
					/>
					<label>&nbsp;Size</label>
				</div>
			</div>
			{size && (
				<div
					className="form-group row"
					hidden={invoiceType.value === "tickets" ? true : false}
				>
					<div className="col-sm-4 d-flex justify-content-center">
						<input
							name="sizeGlobal"
							type="checkbox"
							checked={sizeGlobal}
							onChange={(e) => setSizeGlobal((prevValue) => !prevValue)}
						/>
						<label className="mt-auto mb-auto">&nbsp;Apply To Global</label>
					</div>
					<div className="col-sm-8">
						<label className="text-muted">Fee</label>
						<input
							name="Size"
							type="number"
							className="form-control"
							value={sizeBags}
							onChange={(e) => {
								setSizeBags(e.target.value);
								sizeRef.current = e.target.value ? e.target.value : 0;
								calculateTax();
							}}
						/>
					</div>
				</div>
			)}

			<div
				className="form-group row"
				hidden={invoiceType.value === "tickets" ? true : false}
			>
				<div className="col-sm-6">
					<input
						name="miscelaneos"
						type="checkbox"
						checked={miscelaneos}
						onChange={() => setMiscelaneos((prevValue) => !prevValue)}
					/>
					<label>&nbsp;Miscelaneos</label>
				</div>
			</div>
			{miscelaneos && (
				<div
					className="form-group row"
					hidden={invoiceType.value === "tickets" ? true : false}
				>
					<div className="col-sm-4 d-flex justify-content-center">
						<input
							name="miscelaneosGlobal"
							type="checkbox"
							checked={miscelaneosGlobal}
							onChange={(e) => setMiscelaneosGlobal((prevValue) => !prevValue)}
						/>
						<label className="mt-auto mb-auto">&nbsp;Apply To Global</label>
					</div>
					<div className="col-sm-4">
						<label className="text-muted">Fee</label>
						<input
							name="Miscelaneos"
							type="number"
							className="form-control"
							value={miscelaneosFee}
							onChange={(e) => {
								setMiscelaneosFee(e.target.value);
								miscelaneosRef.current = e.target.value ? e.target.value : 0;
								calculateTax();
							}}
						/>
					</div>
					<div className="col-sm-4">
						<label className="text-muted">Miscelaneos Reason</label>
						<input
							name="miscelaneosReason"
							type="text"
							className="form-control"
							value={miscelaneosReason}
							onChange={(e) => setMiscelaneosReason(e.target.value)}
						/>
					</div>
				</div>
			)}
			<div>
				<LoadList
					list={loadList}
					setList={setLoadList}
					calculateTotals={calculateTotals}
					isTotal={invoiceType.value === "total" ? true : false}
				/>
			</div>
			<div className="form-group row">
				<div className="col-sm-4">
					<label className="text-muted">Fuel Surcharge</label>
					<input
						type="text"
						style={{ textAlign: "right" }}
						className="form-control"
						readOnly
						value={
							invoiceType.value === "tickets"
								? toCurrency(totalsPerTicket.current.tripCharge, 2)
								: fuelSurcharge
								? fuelSurchargeGlobal
									? toCurrency(fuelRef.current * 1, 2)
									: toCurrency(trips * fuelRef.current, 2)
								: toCurrency(0, 2)
						}
					/>
				</div>
				<div className="col-sm-4">
					<label className="text-muted">Shipping Fee</label>
					<input
						type="text"
						style={{ textAlign: "right" }}
						className="form-control"
						readOnly
						value={
							invoiceType.value === "tickets"
								? toCurrency(totalsPerTicket.current.shippingFee, 2)
								: shipping
								? shippingGlobal
									? toCurrency(shipRef.current * 1, 2)
									: toCurrency(shipRef.current * trips, 2)
								: toCurrency(0, 2)
						}
					/>
				</div>
				<div className="col-sm-4">
					<label className="text-muted">Credit Card</label>
					<input
						type="text"
						style={{ textAlign: "right" }}
						className="form-control"
						readOnly
						value={
							isCreditCardPayment
								? toCurrency(creditCardCost, 2)
								: toCurrency(0, 2)
						}
					/>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-3">
					<label className="text-muted">Color</label>
					<input
						type="text"
						style={{ textAlign: "right" }}
						className="form-control"
						readOnly
						value={
							invoiceType.value === "tickets"
								? toCurrency(totalsPerTicket.current.colorFee, 2)
								: color
								? colorGlobal
									? toCurrency(colorRef.current * 1, 2)
									: toCurrency(colorRef.current * trips, 2)
								: toCurrency(0, 2)
						}
					/>
				</div>
				<div className="col-sm-3">
					<label className="text-muted">Fiber</label>
					<input
						type="text"
						style={{ textAlign: "right" }}
						className="form-control"
						readOnly
						value={
							invoiceType.value === "tickets"
								? toCurrency(totalsPerTicket.current.fiberFee, 2)
								: fiber
								? fiberGlobal
									? toCurrency(fiberRef.current * 1, 2)
									: toCurrency(fiberRef.current * trips, 2)
								: toCurrency(0, 2)
						}
					/>
				</div>
				<div className="col-sm-3">
					<label className="text-muted">Size</label>
					<input
						type="text"
						style={{ textAlign: "right" }}
						className="form-control"
						readOnly
						value={
							invoiceType.value === "tickets"
								? toCurrency(totalsPerTicket.current.sizeFee, 2)
								: size
								? sizeGlobal
									? toCurrency(sizeRef.current * 1, 2)
									: toCurrency(sizeRef.current * trips, 2)
								: toCurrency(0, 2)
						}
					/>
				</div>
				<div className="col-sm-3">
					<label className="text-muted">Miscelaneos</label>
					<input
						type="text"
						style={{ textAlign: "right" }}
						className="form-control"
						readOnly
						value={
							invoiceType.value === "tickets"
								? toCurrency(totalsPerTicket.current.miscelaneosFee, 2)
								: miscelaneos
								? miscelaneosGlobal
									? toCurrency(miscelaneosRef.current * 1, 2)
									: toCurrency(miscelaneosRef.current * trips, 2)
								: toCurrency(0, 2)
						}
					/>
				</div>
			</div>

			<div className="form-group row">
				<div className="col-sm-3">
					<label className="text-muted">Subtotal</label>
					<input
						type="text"
						style={{ textAlign: "right" }}
						className="form-control"
						readOnly
						value={toCurrency(subtotal, 2)}
					/>
				</div>
				<div className="col-sm-3">
					<label className="text-muted">Discount</label>
					<input
						type="text"
						style={{ textAlign: "right" }}
						className="form-control"
						readOnly
						value={toCurrency(discountAmount, 2)}
					/>
				</div>
				<div className="col-sm-3">
					<label className="text-muted">Tax</label>
					<input
						type="text"
						style={{ textAlign: "right" }}
						className="form-control"
						readOnly
						value={toCurrency(taxAmount, 2)}
					/>
				</div>
				<div className="col-sm-3">
					<label className="text-muted">Total</label>
					<input
						type="text"
						style={{ textAlign: "right" }}
						className="form-control"
						readOnly
						value={toCurrency(invoiceTotal, 2)}
					/>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">PO</label>
					<input
						type="text"
						className="form-control"
						name="po"
						value={po}
						placeholder="Purchase Order"
						onChange={(e) => setPO(e.target.value)}
					/>
				</div>
				<div className="col-sm-6">
					<label className="text-muted">Term</label>
					<input
						type="text"
						className="form-control"
						name="paymentTerm"
						value={paymentTerm}
						placeholder="Payment Term"
						readOnly
					/>
				</div>
			</div>
			<div className="my-2">
				<button className="btn btn-success" disabled={loading.current}>
					{loading.current ? "Loading..." : "Generate"}
				</button>{" "}
				{loading.current ? (
					<SpinnerButton type={"danger"} />
				) : (
					<button
						className="btn btn-danger"
						type="button"
						onClick={showEtickets}
					>
						Etickets
					</button>
				)}{" "}
				{loading.current ? (
					<SpinnerButton type={"primary"} />
				) : (
					<button
						className="btn btn-primary"
						type="button"
						onClick={copyTickets}
					>
						Copy Tickets
					</button>
				)}
			</div>
		</form>
	);

	const showEtickets = () => {
		const url = "/eticketsjob/" + jobId;
		history.push(url);
	};

	const copyTickets = () => {
		let textContent = "";
		loadList.map((i, index) => {
			if (index < loadList.length - 1) {
				textContent += i.deliveryTicket + ",";
			} else {
				textContent += i.deliveryTicket;
			}
		});

		navigator.clipboard.writeText(textContent);
	};

	return <>{InvoiceForm()}</>;
};

export default JobToInvoiceModal;
