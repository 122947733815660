import React, { createContext, useState } from "react";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
	const [notificationCount, setNotificationCount] = useState(0);
	const [notifications, setNotifications] = useState([]);

	return (
		<NotificationContext.Provider
			value={{
				notificationCount,
				setNotificationCount,
				notifications,
				setNotifications,
			}}
		>
			{children}
		</NotificationContext.Provider>
	);
};

export default NotificationContext;
