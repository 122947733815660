import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";

const ReadLoad2 = ({ loads, jobID, loadTotal }) => {
	const [tableCsvContent, setTableCsvContent] = useState([]);

	useEffect(() => {
		createTableContent();
	}, [loads]);
	const columns = [
		{ key: "jobID", name: "Job Number" },
		{ key: "driver", name: "Driver" },
		{ key: "truck", name: "Truck" },
		{ key: "load", name: "Load" },
		{ key: "deliveryTicket", name: "Ticket" },
		{ key: "orderCode", name: "Order Code" },
		{ key: "date", name: "Date" },
		{ key: "time", name: "Time" },
	];

	const headers = () => {
		const columnsCSV = columns;
		columnsCSV.pop();
		return columnsCSV.map((item) => {
			return {
				key: item.key,
				label: item.name,
			};
		});
	};

	const createTableContent = () => {
		let dataFormatted = [];
		loads.map((ld) => {
			const row = {
				jobID: jobID,
				driver: ld.driver,
				truck: ld.truckNumber,
				load: ld.yards,
				deliveryTicket: ld.deliveryTicket,
				orderCode: ld.orderCode,
				date: new Date(ld.createdAt).toLocaleDateString(),
				time: new Date(ld.createdAt).toLocaleTimeString(),
			};
			dataFormatted.push(row);
		});
		setTableCsvContent(dataFormatted);
	};

	const DrawLoad = () => {
		if (loads.length > 0) {
			return loads.map((ld) => {
				return (
					<tr key={ld._id}>
						<td>{ld.driver}</td>
						<td>{ld.truckNumber} </td>
						<td>{ld.yards}</td>
						<td>{ld.deliveryTicket} </td>
						<td> {ld.orderCode} </td>
						<td> {new Date(ld.createdAt).toLocaleDateString()} </td>
						<td> {new Date(ld.createdAt).toLocaleTimeString()} </td>
					</tr>
				);
			});
		} else {
			return (
				<tr>
					<td colSpan="6" style={{ textAlign: "center" }}>
						No load yet
					</td>
				</tr>
			);
		}
	};

	return (
		<div>
			Load Total: {Math.round(loadTotal * 100) / 100}
			<table className="table">
				<thead>
					<tr>
						<th>Driver</th>
						<th>Truck</th>
						<th>Load</th>
						<th>Ticket</th>
						<th>Order Code</th>
						<th>Date</th>
						<th>Time</th>
					</tr>
				</thead>
				<tbody>
					<DrawLoad />
				</tbody>
			</table>
			<CSVLink
				data={tableCsvContent}
				headers={headers()}
				filename={"57concrete-job-report.csv"}
			>
				Download report
			</CSVLink>
		</div>
	);
};

export default ReadLoad2;
