import React, { useState, useEffect } from "react";
import axios from "axios";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
import Marker from "./Marker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { api } from "../utils/Api";

const AddressGeolocation = (props) => {
	const [latitude, setLatitude] = useState(26.2787785);
	const [longitude, setLongitude] = useState(-98.4021818);
	const [locality, setLocality] = useState("");
	const [address, setAddress] = useState("");
	const [destLat, setDestLat] = useState("");
	const [destLng, setDestLng] = useState("");
	const [error, setError] = useState(false);
	const [farAway, setFarAway] = useState(false);
	const [search, setSearch] = useState(props.search);
	const [distance, setDistance] = useState(null);
	const [duration, setDuration] = useState(null);
	const [name, setName] = useState("57concrete");

	const googleApi =
		process.env.REACT_APP_GOOGLE_MAP_API ||
		"AIzaSyClyO950zwD8xsLYO7VHcyrnokEU6njbVs";

	useEffect(() => {
		if (props.plant) {
			getPlantInfo();
		} else {
			getCompanyInfo();
		}
	}, []);

	const getCompanyInfo = async () => {
		const url = api + "/company";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			setLatitude(data.latitude);
			setLongitude(data.longitude);
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	const getPlantInfo = async () => {
		const url = api + `/plant/${props.plant}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			setLatitude(data.latitude);
			setLongitude(data.longitude);
			setName(data.name);
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	// address geocoding
	const getCoordinates = (address) => {
		Geocode.setApiKey(googleApi);
		Geocode.fromAddress(address).then(
			(response) => {
				const addressComponentes = response.results[0].address_components;
				const { lat, lng } = response.results[0].geometry.location;

				let locality = "";
				addressComponentes.forEach((item) => {
					const { types } = item;
					if (types[0] === "locality") {
						locality = item.long_name;
					}
				});
				setLocality(locality);
				setDestLat(lat);
				setDestLng(lng);
				calculateDistance(lat, lng);
			},
			(error) => {
				setError(true);
				console.error(error);
			}
		);
	};

	// Distance to customer
	const calculateDistance = async (lat, lng) => {
		const ubicacion = {
			lat,
			lng,
		};

		let url = "";
		if (props.plant) {
			url = api + `/distanceplant/${props.plant}`;
		} else {
			url = api + "/distance";
		}

		try {
			const { data } = await axios.post(url, ubicacion);
			const distance = data.distance.text;
			const duration = data.duration.text;
			const recorrido = distance.split(" ");
			if (parseFloat(recorrido[0].trim()) > 100) {
				setFarAway(true);
			} else {
				setDistance(distance);
				setDuration(duration);
			}
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	const onClick = (event) => {
		Geocode.setApiKey(googleApi);
		Geocode.fromLatLng(event.lat, event.lng).then(
			(response) => {
				console.log(response);
				const formattedAddress = response.results[0].formatted_address;
				const addressComponentes = response.results[0].address_components;
				const lat = event.lat;
				const lng = event.lng;

				let locality = "";
				addressComponentes.forEach((item) => {
					const { types } = item;
					if (types[0] === "locality") {
						locality = item.long_name;
					}
				});
				setAddress(formattedAddress);
				setLocality(locality);
				setDestLat(lat);
				setDestLng(lng);
				calculateDistance(lat, lng);
			},
			(error) => {
				setError(true);
				console.error(error);
			}
		);
	};

	const defaultMap = {
		center: {
			lat: latitude,
			lng: longitude,
		},
		zoom: 8,
	};

	// Geocode Address
	const geoCode = () => {
		setError(false);
		getCoordinates(address);
	};

	// pass data to parent
	const saveAddress = () => {
		const data = {
			address,
			destLat,
			destLng,
			distance,
			duration,
			locality,
		};
		props.parentCallback(data);
	};

	// show text in map
	const AnyReactComponent = ({ text }) => <div>{text}</div>;

	return (
		<div>
			{error && <div>Address not found</div>}
			{farAway && <div>Address out of coverage</div>}
			{search && (
				<div>
					<div className="form-group row">
						<div className="col-sm-10">
							<input
								type="text"
								name="address"
								id="address"
								value={address}
								size="45"
								maxLength="100"
								onChange={(e) => setAddress(e.target.value)}
							/>
						</div>
						<div className="col-sm-1">
							<button className="btn btn-primary" onClick={geoCode}>
								<FontAwesomeIcon icon={faSearch} />
							</button>
						</div>
					</div>
				</div>
			)}
			<div style={{ height: "60vh", width: "100%" }}>
				<GoogleMapReact
					bootstrapURLKeys={{
						key: googleApi,
						version: "weekly",
					}}
					options={{
						fullscreenControl: true,
						disableDefaultUI: true,
						cameraControl: true,
					}}
					defaultCenter={defaultMap.center}
					defaultZoom={defaultMap.zoom}
					onClick={onClick}
					yesIWantToUseGoogleMapApiInternals
				>
					{!props.single && (
						<Marker lat={latitude} lng={longitude} color="red" />
					)}
					{!props.single && (
						<AnyReactComponent lat={latitude} lng={longitude} text={name} />
					)}
					{destLat && <Marker lat={destLat} lng={destLng} color="green" />}
				</GoogleMapReact>
			</div>
			<div className="my-2">
				{distance && (
					<button className="btn btn-success" onClick={saveAddress}>
						Save address
					</button>
				)}
			</div>
		</div>
	);
};

export default AddressGeolocation;
