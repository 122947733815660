import React, { useEffect, useState, useContext } from "react";
import { Badge, Button, Card, Drawer } from "antd";
import { MailOutlined } from "@ant-design/icons";
import NotificationContext from "../../context/NotificationContext";
import axios from "axios";
import { api } from "../utils/Api";

const NotificationManager = () => {
	const [open, setOpen] = useState(false);
	const {
		notificationCount,
		setNotificationCount,
		notifications,
		setNotifications,
	} = useContext(NotificationContext);

	const showNotificationDrawer = async () => {
		setNotificationCount(0);
		setOpen(true);
	};
	const onCloseNotificationDrawer = async () => {
		setOpen(false);
		setNotifications((prev) => [
			...prev.map((item) => {
				return { ...item, status: "Default" };
			}),
		]);
		await updateNotifications().finally(() => {
			setOpen(false);
		});
	};

	useEffect(() => {
		getListOfNotification();
	}, []);

	const getListOfNotification = async () => {
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url = api + `/notification/list`;

		try {
			const { data } = await axios.get(url);
			let list = [];

			if (data.success) {
				let count = 0;
				data.payload.map((item) => {
					list.push({
						_id: item._id,
						type: item.type,
						message: item.message,
						status: item.read ? "Default" : "New",
					});
					if (!item.read) {
						count++;
					}
				});
				setNotificationCount(count);
				setNotifications(list);
			}
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const updateNotifications = async () => {
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const filtered = notifications.filter((item) => item.status === "New");

			await Promise.all(
				filtered.map(async (item) => {
					const url = api + `/notification/update/read/${item._id}`;
					await axios.post(url);
				})
			);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	return (
		<>
			<Badge count={notificationCount} overflowCount={15}>
				<Button
					icon={<MailOutlined />}
					type="primary"
					onClick={showNotificationDrawer}
				/>
			</Badge>
			<Drawer
				title="Notifications"
				onClose={onCloseNotificationDrawer}
				open={open}
			>
				{notifications.map((item, index) => (
					<Badge.Ribbon
						text={item.status}
						color={item.status === "New" ? "green" : "blue"}
						key={index}
					>
						<Card title={item.type} size="small" style={{ marginBottom: 10 }}>
							{item.message}
						</Card>
					</Badge.Ribbon>
				))}
			</Drawer>
		</>
	);
};

export default NotificationManager;
