import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Register from "./components/client/Register";
import Signin from "./components/user/Signin";
import Home from "./components/layout/Home";
import Dashboard from "./components/layout/Dashboard";
import { PrivateRoute } from "./components/routing/PrivateRoute";
import NotFound from "./components/layout/NotFound";
// customer company
import ClientNew from "./components/client/ClientNew";
import ListaClientes from "./components/client/ListaCliente";
import Cliente from "./components/client/Client";
import ClientRegistration from "./components/client/ClientRegistration";
import ClientConfirmation from "./components/client/ClientConfirmation";
import CustomerType from "./components/customertype/CustomerTypeList";
import ClientSummary from "./components/client/ClientSummary";
import ClientList from "./components/client/ClientList";
import ClientWithInvoice from "./components/client/ClientWithInvoice";
// Seller
import SellerList from "./components/seller/SellerList";
import SellerNew from "./components/seller/SellerNew";
import Seller from "./components/seller/Seller";
import SalesBySeller from "./components/seller/SalesBySeller";
// Admins
import AdminList from "./components/admin/AdminList";
import AdminNew from "./components/admin/AdminNew";
import Admin from "./components/admin/Admin";
// Driver
import DriverList from "./components/driver/DriverList";
import DriverNew from "./components/driver/DriverNew";
import Driver from "./components/driver/Driver";
import DriverQr from "./components/driver/DriverQr";
// PSI
import Psi from "./components/psi/Psi";
import PsiList from "./components/psi/PsiList";
import PsiNew from "./components/psi/PsiNew";
// job
import JobNuevo from "./components/job/JobNuevo";
import JobForCustomer from "./components/job/JobForCustomer";
import DailyAppointment from "./components/job/DailyAppointment";
import DAppointment from "./components/job/DAppointment";
import Job from "./components/job/Job";
import DailyCustomer from "./components/job/DailyCustomer";
import JobPhotos from "./components/job/JobPhotos";
import JobHistory from "./components/job/JobHistory";
import JobInspection from "./components/job/JobInspection";
import JobInspect from "./components/job/JobInspect";
import JobsPending from "./components/job/JobsPending";
import JobAppointment from "./components/job/JobAppointment";
import DashboardManagement from "./components/job/DashboardManagement";
import Daily from "./components/job/Daily";
import DailyReadOnly from "./components/job/DailyReadOnly";
// import DailyKanban from './components/job/DailyKanban'
// credit
import CreditNew from "./components/credit/CreditNew";
import CreditList from "./components/credit/CreditList";
import Credit from "./components/credit/Credit";
// pdf
import ClientForm from "./components/pdf/ClientForm";
import CreditForm from "./components/pdf/CreditForm";
import InvoicePdf from "./components/pdf/InvoicePdf";
import QuotePdf from "./components/pdf/QuotePdf";
import Eticket from "./components/pdf/Eticket";
// invoice
import InvoiceNew from "./components/invoice/InvoiceNew";
import DailySales from "./components/invoice/DailySales";
import PeriodSales from "./components/invoice/PeriodSales";
import AccountsReceivable from "./components/invoice/AccountsReceivable";
import JobToInvoice from "./components/invoice/JobToInvoice";
import Invoice from "./components/invoice/Invoice";
import ClientInvoices from "./components/invoice/ClientInvoices";
import MixDesignInvoices from "./components/invoice/MixDesignInvoices";
import PaymentPDF from "./components/pdf/PaymentPdf";
import GeneralPayments from "./components/invoice/GeneralPayments";
// phone quote
import QuoteNew from "./components/quote/QuoteNew";
import QuoteList from "./components/quote/QuoteList";
// Company update
import Company from "./components/company/Company";
// SMS
import Bulksms from "./components/sms/Bulksms";
// Reports
import NewReport from "./components/reports/NewReport";
import ClockReport from "./components/reports/ClockReport";
import ReportJob from "./components/reports/ReportJob";
import ReportLoad from "./components/reports/ReportLoad";
import JobReportRun from "./components/reports/JobReportRun";
import ReportList from "./components/reports/ReportList";
import Aviability from "./components/reports/Aviability";
import ReportTopCustomers from "./components/reports/ReportTopCustomers";
import TopLocalities from "./components/reports/TopLocalities";
import TopForeman from "./components/reports/TopForeman";
import ReconciliationReport from "./components/reports/ReconciliationReport";
import PaymentReport from "./components/reports/PaymentReport";
import ReportTrips from "./components/reports/ReportTrips";
import ReportPaymentDriver from "./components/reports/ReportPaymentDriver";
import ReportCustomerSales from "./components/reports/ReportCustomerSales";
import QuadrantsReport from "./components/quadrant/QuadrantsReport";
import ETicketReport from "./components/reports/ETicketReport";
import Heatmap from "./components/reports/Heatmap";
import HeatmapSellers from "./components/reports/HeatmapSellers";
import ReportTruckTrips from "./components/reports/ReportTruckTrips";
import ReportTruckYards from "./components/reports/ReportTruckYards";
// Truck
import TruckNew from "./components/truck/TruckNew";
import TruckList from "./components/truck/TruckList";
import Truck from "./components/truck/Truck";
import TruckQr from "./components/truck/TruckQr";
import TruckEfficiency from "./components/truck/TruckEfficiency";
// Referral
import ReferralNew from "./components/referral/ReferralNew";
import ReferralList from "./components/referral/ReferralList";
import Referral from "./components/referral/Referral";
import ReferralPdf from "./components/referral/ReferralPdf";
// Plants
import PlantList from "./components/plant/PlantList";
import Plant from "./components/plant/Plant";
import PlantNew from "./components/plant/PlantNew";
// Project
import ProjectList from "./components/project/ProjectList";
import Project from "./components/project/Project";
import ProjectNew from "./components/project/ProjectNew";
// redux
import { Provider } from "react-redux";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import Settings from "./components/layout/Settings";
// loads
import Capacity from "./components/load/Capacity";
// aggregate
import AggregateNew from "./components/aggregate/AggregateNew";
import Aggregate from "./components/aggregate/Aggregate";
import AggregateList from "./components/aggregate/AggregateList";
import Production from "./components/aggregate/Production";
// Quotes
import NewQuote from "./components/quote/NewQuote";
import Quote from "./components/quote/Quote";
import QuoteListing from "./components/quote/QuoteListing";
// Cog
import NewCog from "./components/cog/NewCog";
import CogList from "./components/cog/CogList";
import Cog from "./components/cog/Cog";
// uploads
import SalesMix from "./components/uploads/SalesMix";
import OrderSummary from "./components/uploads/OrderSummary";
import MaterialInventory from "./components/uploads/MaterialInventory";
import UploadList from "./components/uploads/UploadList";
import OrderList from "./components/uploads/OrderList";
import OrderListDetail from "./components/uploads/OrderListDetail";
import SalesMixList from "./components/uploads/SalesMixList";
import SalesMixDetail from "./components/uploads/SalesMixDetail";
// Email list
import EmailList from "./components/emaillist/EmailList";
import EmailListEdit from "./components/emaillist/EmailListEdit";
import EmailNew from "./components/emaillist/EmailNew";
// Foreman
import Foreman from "./components/foreman/Foreman";
import ForemanNew from "./components/foreman/ForemanNew";
import ForemanList from "./components/foreman/ForemanList";
// Build Development
import BuildDevelopmentList from "./components/buildDevelopment/BuildDevelopmentList";
import BuildDevelopment from "./components/buildDevelopment/BuildDevelopment";
import NewBuildDevelopment from "./components/buildDevelopment/NewBuildDevelopment";
// Forecast
import ForecastBySeller from "./components/forecast/ForecastBySeller";
import SellerAccomplishment from "./components/forecast/SellerAccomplishment";
// Sales
import SalesDashboard from "./components/sales/SalesDashboard";
import SellerReport from "./components/sales/SellerReport";
import YearChart from "./components/sales/YearChart";
import SellerYardsSold from "./components/sales/SellerYardsSold";
import SellerYardDev from "./components/sales/SellerYardDev";
import OpportunityStatus from "./components/proposal/OpportunityStatus";
import OpportunityStatusType from "./components/proposal/OpportunityStatusType";
import NewProposal from "./components/proposal/NewProposal";
import ProspectionRegistration from "./components/prospection/ProspectionRegistration";
import Proposals from "./components/proposal/Proposals";
// Lolaylty plan
import LoyaltyBySeller from "./components/loyalty/LoyaltyBySeller";
import LoyaltyAcomplishment from "./components/forecast/LoyaltyAccomplishment";
// integration with digital fleet
import Components from "./components/fleet/Components";
// Driver Payment Peer Trip
import CityDeliveryFee from "./components/delivery/CityDeliveryFee";
import CityNew from "./components/delivery/CityNew";
import City from "./components/delivery/City";
import DriverPaymentInvoice from "./components/pdf/DriverPaymentInvoice";
import DriverPaymentPreInvoice from "./components/pdf/DriverPaymentPreInvoice";
import DriverInvoices from "./components/driverinvoices/DriverInvoices";
import Proposal from "./components/proposal/Proposal";
import ProspectionList from "./components/prospection/ProspectionList";
import Prospection from "./components/prospection/Prospection";
import GeneralPaymentPDF from "./components/pdf/GeneralPaymentPdf";
// Certification
import CertForm from "./components/certification/CertForm";
import Confirmation from "./components/certification/Confirmation";
import Lineup from "./components/load/Lineup";
import TruckMap from "./components/truck/TruckMap";
import TruckMap2 from "./components/truck/TruckMap2";
import NewEmployee from "./components/rh/employee/NewEmployee";
import ExpensesPDF from "./components/pdf/ExpensesPdf";
import ExpensesWPPDF from "./components/pdf/ExpensesWPPdf";
import EmployeeWriteUpPDF from "./components/pdf/EmployeeWriteUpPdf";
import NewDepartment from "./components/rh/department/NewDepartment";
import NewPosition from "./components/rh/position/NewPosition";
import Employees from "./components/rh/employee/Employees";
import Employee from "./components/rh/employee/Employee";
import Departments from "./components/rh/department/Departments";
import Department from "./components/rh/department/Department";
import Positions from "./components/rh/position/Positions";
import Position from "./components/rh/position/Position";
import ApplicationEmploymentPDF from "./components/pdf/ApplicationEmploymentPdf";
import EmailValidation from "./components/rh/email/EmailValidation";
import EmailsValidation from "./components/rh/email/EmailsValidation";
import FinanceList from "./components/finance/FinanceList";
import Finance from "./components/finance/Finance";
import FinanceNew from "./components/finance/FinanceNew";
import BatchList from "./components/batch/BatchList";
import BatchNew from "./components/batch/BatchNew";
import Batch from "./components/batch/Batch";
import EmployeeExpenses from "./components/rh/employee/EmployeeExpenses";
import EmployeeExpensesWithoutProof from "./components/rh/employee/EmployeeExpensesWithoutProof";
import EmployeeWriteUp from "./components/rh/employee/EmployeeWriteUp";
import ListPrice from "./components/price/ListPrice";
import EticketJob from "./components/pdf/EticketJobPdf";
import InspectionReport from "./components/reports/InspectionReport";
import InspectionPDF from "./components/pdf/InspectionPdf";
import InspectionDefects from "./components/reports/InspectionDefects";
import QualityControlReport from "./components/reports/QualityControlReport";
import HistoryReport from "./components/reports/HistoryReport";
import FailuresReport from "./components/reports/FailuresReport";
import WorkOrders from "./components/wo/WorkOrders";
import WorkOrder from "./components/wo/WorkOrder";
import MechanicList from "./components/mechanic/MechanicList";
import MechanicNew from "./components/mechanic/MechanicNew";
import ETicketModifiedReport from "./components/reports/EticketModifiedReport";
import EticketModified from "./components/pdf/EticketModified";
import Mechanic from "./components/mechanic/Mechanic";
import MyWorkOrders from "./components/wo/MyWorkOrders";
import WorkOrderPDF from "./components/pdf/WorkOrderPdf";
import InventoryList from "./components/inventory/InventoryList";
import NewInventory from "./components/inventory/NewInventory";
import ProviderList from "./components/inventory/ProviderList";
import NewProvider from "./components/inventory/NewProvider";
import Provider2 from "./components/inventory/Provider";
import Inventory from "./components/inventory/Inventory";
import RequestInventoryList from "./components/inventory/RequestInventoryList";
import NewInventoryRequested from "./components/inventory/NewRequestInventory";
import InventoryRequested from "./components/inventory/RequestInventory";
import ManagerList from "./components/manager/ManagerList";
import NewManager from "./components/manager/NewManager";
import Manager from "./components/manager/Manager";
import DailyAppointment2 from "./components/job/DailyAppointment2";
import NewJob from "./components/job/NewJob";
import WebLogisticsDashboard from "./components/weblogistics/WebLogisticsDashboard";
import LogisticsDriverList from "./components/weblogistics/LogisticsDriverList";
import LogisticsTruckList from "./components/weblogistics/LogisticsTruckList";
import NewLogisticsTruck from "./components/weblogistics/NewLogisticsTruck";
import LogisticsTruck from "./components/weblogistics/LogisticsTruck";
import NewLogisticsDriver from "./components/weblogistics/NewLogisticsDriver";
import LogisticsDriver from "./components/weblogistics/LogisticsDriver";
import LogisticsPDF from "./components/pdf/LogisticsPdf";
import InspectionDriverReport from "./components/reports/InspectionDriverDeport";
import SMSList from "./components/sms/SmsList";
import SMSEdit from "./components/sms/SmsEdit";
import LogisticsTrip from "./components/weblogistics/LogisticsTrip";
import QCList from "./components/qc/QCList";
import NewQC from "./components/qc/NewQC";
import QC from "./components/qc/QC";
import LogisticsReportTripsPerTruck from "./components/weblogistics/LogisticsReportTripsPerTruck";
import LogisticsReportMaterialPerPlant from "./components/weblogistics/LogisticsReportMaterialPerPlant";
import LogisticsReportTripsPerDriver from "./components/weblogistics/LogisticsReportPerTripsPerDriver";
import LogisticsReportTotalGeneralTrips from "./components/weblogistics/LogisticsReportTotalGeneralTrips";
import LogisticsReportInspection from "./components/weblogistics/LogisticsReportInspection";
import LogisticsReportInspectionDriver from "./components/weblogistics/LogisticsReportDriverInspection";
import LogisticsReportMilesPerTruck from "./components/weblogistics/LogisticsReportMilesPerTruck";
import LogisticsReportMilesPerDriver from "./components/weblogistics/LogisticsReportMilesPerDriver";
import InspectionLogisticsPDF from "./components/weblogistics/InspectionLogisticsPDF";
import ReportAverageTimeEticket from "./components/reports/ReportAverageTimeEticket";
import ETicketTripsReport from "./components/reports/EticketTripsReport";
import EticketReportTripsPerTruck from "./components/reports/EticketReportTripsPerTruck";
import EticketReportTripsPerDriver from "./components/reports/EticketReportPerTripsPerDriver";
import EticketReportMilesPerTruck from "./components/reports/EticketReportMilesPerTruck";
import EticketReportMilesPerDriver from "./components/reports/EticketReportMilesPerDriver";
import NewOpportunity from "./components/opportunity/NewOpportunity";
import OpportunityDashboard from "./components/opportunity/OpportunityDashboard";
import Opportunity from "./components/opportunity/Opportunity";
import LoanDashboard from "./components/loan/LoanDashboard";
import NewLoan from "./components/loan/NewLoan";
import Loan from "./components/loan/Loan";
import OpportunityQuadrants from "./components/opportunity/OpportunityQuadrants";
import LoanPaymentsReport from "./components/loan/LoanPaymentsReport";
import VehicleList from "./components/vehicle/VehicleList";
import NewVehicle from "./components/vehicle/NewVehicle";
import Vehicle from "./components/vehicle/Vehicle";
import OfficeTruckList from "./components/vehicle/TruckList";
import NewOfficeTruck from "./components/vehicle/NewTruck";
import OfficeTruck from "./components/vehicle/Truck";
import LienDashboard from "./components/lien/LienDashboard";
import NewLien from "./components/lien/NewLien";
import LienPDF from "./components/pdf/LienPdf";
import LoanPaymentsDetailed from "./components/loan/LoanPaymentsDetailed";
import Lien from "./components/lien/Lien";
import ClaimDashboard from "./components/claim/ClaimDashboard";
import NewClaim from "./components/claim/NewClaim";
import Claim from "./components/claim/Claim";
import LogisticsReportDetailed from "./components/weblogistics/LogisticsReportDetailed";
import TransactionDashboard from "./components/transaction/TransactionDashboard";
import DieselLoadingReport from "./components/dieselloading/DieselLoadingReport";
import DirectorsManagement from "./components/directorsmanagement/DirectorsManagement";
import LogisticsMaterialPDF from "./components/pdf/LogisticsMaterialPdf";
import JobYardsByWeekReport from "./components/reports/JobYardsByWeekReport";
import Job2 from "./components/job/Job2";

if (localStorage.token) {
	setAuthToken(localStorage.token);
}

const Routes = () => {
	// Tomar el usuario si está en cookie para no usar login
	// useEffect(() => {
	//     store.dispatch(loadUser())
	// }, [])
	// Ruta a Reconciliation report agregada

	return (
		<Provider store={store}>
			<BrowserRouter>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route exact path="/certform" component={CertForm} />
					<Route exact path="/confirmation" component={Confirmation} />
					<Route exact path="/register" component={Register} />
					<Route exact path="/signin" component={Signin} />
					<Route exact path="/credit" component={CreditNew} />
					<Route exact path="/nuevocliente" component={ClientRegistration} />
					<Route
						exact
						path="/clientconfirmation/:id"
						component={ClientConfirmation}
					/>
					<Route exact path="/credit/:id" component={Credit} />
					<Route exact path="/e-ticket/:id" component={Eticket} />
					<Route exact path="/opportunity/new" component={NewOpportunity} />
					<PrivateRoute exact path="/creditlist" component={CreditList} />
					<PrivateRoute exact path="/driverlist" component={DriverList} />
					<PrivateRoute exact path="/drivernew" component={DriverNew} />
					<PrivateRoute exact path="/driver/:id" component={Driver} />
					<PrivateRoute exact path="/driverqr/:id" component={DriverQr} />
					<PrivateRoute exact path="/psilist" component={PsiList} />
					<PrivateRoute exact path="/psinew" component={PsiNew} />
					<PrivateRoute exact path="/psi/:id" component={Psi} />
					<PrivateRoute exact path="/dashboard" component={Dashboard} />
					<PrivateRoute exact path="/newclient" component={ClientNew} />
					<PrivateRoute exact path="/listacliente" component={ListaClientes} />
					<PrivateRoute exact path="/client/:id" component={Cliente} />
					<PrivateRoute exact path="/jobnuevo" component={JobNuevo} />
					<PrivateRoute exact path="/newjob" component={NewJob} />
					<PrivateRoute
						exact
						path="/dailycustomer/:id"
						component={DailyCustomer}
					/>
					<PrivateRoute exact path="/jobphotos/:id" component={JobPhotos} />
					<PrivateRoute exact path="/job/:id" component={Job2} />
					{/* <PrivateRoute exact path="/job2/:id" component={Job2} /> */}
					<PrivateRoute
						exact
						path="/jobforcustomer/:id"
						component={JobForCustomer}
					/>
					<PrivateRoute exact path="/sellerlist" component={SellerList} />
					<PrivateRoute exact path="/customertype" component={CustomerType} />
					<PrivateRoute exact path="/adminlist" component={AdminList} />
					<PrivateRoute exact path="/adminnew" component={AdminNew} />
					<PrivateRoute exact path="/admin/:id" component={Admin} />
					<PrivateRoute exact path="/sellernew" component={SellerNew} />
					<PrivateRoute exact path="/seller/:id" component={Seller} />
					<PrivateRoute exact path="/clientpdf/:id" component={ClientForm} />
					<PrivateRoute exact path="/creditpdf/:id" component={CreditForm} />
					<PrivateRoute exact path="/invoicepdf/:id" component={InvoicePdf} />
					<PrivateRoute
						exact
						path="/dailyappointment"
						component={DailyAppointment}
					/>
					<PrivateRoute
						exact
						path="/dailyappointment2"
						component={DailyAppointment2}
					/>
					<PrivateRoute exact path="/dappointment" component={DAppointment} />
					<PrivateRoute exact path="/settings" component={Settings} />
					<PrivateRoute exact path="/quotenew" component={QuoteNew} />
					<PrivateRoute exact path="/quotelist" component={QuoteList} />
					<PrivateRoute exact path="/company" component={Company} />
					<PrivateRoute exact path="/bulksms" component={Bulksms} />
					<PrivateRoute exact path="/invoice/:id" component={InvoiceNew} />
					<PrivateRoute exact path="/invoiceedit/:id" component={Invoice} />
					<PrivateRoute
						exact
						path="/accountsreceivable"
						component={AccountsReceivable}
					/>
					<PrivateRoute exact path="/dailysales" component={DailySales} />
					<PrivateRoute exact path="/periodsales" component={PeriodSales} />
					<PrivateRoute exact path="/newreport" component={NewReport} />
					<PrivateRoute route path="/clockreport" component={ClockReport} />
					<PrivateRoute path="/reportjob" component={ReportJob} />
					<PrivateRoute path="/reporttrips" component={ReportTrips} />
					<PrivateRoute path="/reportload" component={ReportLoad} />
					<PrivateRoute path="/reportjobrun/:id" component={JobReportRun} />
					<PrivateRoute path="/reportlist" component={ReportList} />
					<PrivateRoute
						path="/reportpaymentdriver"
						component={ReportPaymentDriver}
					/>
					<PrivateRoute
						path="/reportcustomersales"
						component={ReportCustomerSales}
					/>
					<PrivateRoute path="/trucklist" component={TruckList} />
					<PrivateRoute path="/newtruck" component={TruckNew} />
					<PrivateRoute path="/truck/:id" component={Truck} />
					<PrivateRoute path="/truckqr/:id" component={TruckQr} />
					<PrivateRoute path="/newreferral" component={ReferralNew} />
					<PrivateRoute path="/referrallist" component={ReferralList} />
					<PrivateRoute path="/referral/:id" component={Referral} />
					<PrivateRoute exact path="/referralpdf/:id" component={ReferralPdf} />
					<PrivateRoute path="/jobhistory/:id" component={JobHistory} />
					<PrivateRoute path="/jobinspection" component={JobInspection} />
					<PrivateRoute path="/jobspending" component={JobsPending} />
					<PrivateRoute path="/plantlist" component={PlantList} />
					<PrivateRoute path="/plant/:id" component={Plant} />
					<PrivateRoute path="/newplant" component={PlantNew} />
					<PrivateRoute path="/capacity" component={Capacity} />
					<PrivateRoute path="/newproject" component={ProjectNew} />
					<PrivateRoute path="/projectlist" component={ProjectList} />
					<PrivateRoute path="/project/:id" component={Project} />
					<PrivateRoute path="/jobappointment" component={JobAppointment} />
					<PrivateRoute path="/newaggregate" component={AggregateNew} />
					<PrivateRoute path="/aggregate/:id" component={Aggregate} />
					<PrivateRoute path="/aggregatelist" component={AggregateList} />
					<PrivateRoute
						path="/dashboardmanagement"
						component={DashboardManagement}
					/>
					<PrivateRoute path="/availability" component={Aviability} />
					<PrivateRoute path="/truckefficiency" component={TruckEfficiency} />
					<PrivateRoute path="/production" component={Production} />
					<PrivateRoute path="/daily" component={DAppointment} />
					<PrivateRoute exact path="/quotepdf/:id" component={QuotePdf} />
					<PrivateRoute
						exact
						path="/dailyreadonly/:id"
						component={DailyReadOnly}
					/>
					<PrivateRoute
						exact
						path="/topcustomers"
						component={ReportTopCustomers}
					/>
					<PrivateRoute exact path="/newquote" component={NewQuote} />
					<PrivateRoute exact path="/quote/:id" component={Quote} />
					<PrivateRoute exact path="/quotelisting" component={QuoteListing} />
					<PrivateRoute exact path="/newcog" component={NewCog} />
					<PrivateRoute exact path="/coglist" component={CogList} />
					<PrivateRoute exact path="/cog/:id" component={Cog} />
					<PrivateRoute exact path="/toplocalities" component={TopLocalities} />
					<PrivateRoute exact path="/salesmixupload" component={SalesMix} />
					<PrivateRoute
						exact
						path="/ordersummaryupload"
						component={OrderSummary}
					/>
					<PrivateRoute exact path="/ordersummarylist" component={OrderList} />
					<PrivateRoute exact path="/salesmixlist" component={SalesMixList} />
					<PrivateRoute
						exact
						path="/materialinventoryupload"
						component={MaterialInventory}
					/>
					<PrivateRoute exact path="/newemaillist" component={EmailNew} />
					<PrivateRoute exact path="/emaillist/:id" component={EmailListEdit} />
					<PrivateRoute exact path="/emaillist" component={EmailList} />
					<PrivateRoute exact path="/newforeman" component={ForemanNew} />
					<PrivateRoute exact path="/foremanlist" component={ForemanList} />
					<PrivateRoute exact path="/foreman/:id" component={Foreman} />
					<PrivateRoute
						exact
						path="/jobtoinvoice/:id"
						component={JobToInvoice}
					/>
					<PrivateRoute
						exact
						path="/builddevelopment"
						component={BuildDevelopmentList}
					/>
					<PrivateRoute
						exact
						path="/builddevelopment/:id"
						component={BuildDevelopment}
					/>
					<PrivateRoute
						exact
						path="/newbuilddevelopment"
						component={NewBuildDevelopment}
					/>
					<PrivateRoute exact path="/topforeman" component={TopForeman} />
					<PrivateRoute
						exact
						path="/forecastbyseller/:id"
						component={ForecastBySeller}
					/>
					<PrivateRoute
						exact
						path="/clientsummary/:id"
						component={ClientSummary}
					/>
					<PrivateRoute path="/salesdashboard" component={SalesDashboard} />
					<PrivateRoute path="/clientlist" component={ClientList} />
					<PrivateRoute path="/sellerreport" component={SellerReport} />
					<PrivateRoute path="/yearchart" component={YearChart} />
					<PrivateRoute exact path="/uploadlist" component={UploadList} />
					<PrivateRoute
						exact
						path="/selleraccomplishment/:id"
						component={SellerAccomplishment}
					/>
					<PrivateRoute
						exact
						path="/clientwithinvoice"
						component={ClientWithInvoice}
					/>
					<PrivateRoute
						exact
						path="/clientinvoices/:id"
						component={ClientInvoices}
					/>
					<PrivateRoute exact path="/loyalty/:id" component={LoyaltyBySeller} />
					<PrivateRoute
						exact
						path="/orderlistdetail/:id"
						component={OrderListDetail}
					/>
					<PrivateRoute
						exact
						path="/salesmixdetail/:id"
						component={SalesMixDetail}
					/>
					<PrivateRoute
						exact
						path="/selleryardssold/:id"
						component={SellerYardsSold}
					/>
					<PrivateRoute
						exact
						path="/selleryardsdev/:id"
						component={SellerYardDev}
					/>
					<PrivateRoute
						exact
						path="/reconciliationreport"
						component={ReconciliationReport}
					/>
					<PrivateRoute
						exact
						path="/loyaltyaccomplishment/:id"
						component={LoyaltyAcomplishment}
					/>
					<PrivateRoute exact path="/paymentreport" component={PaymentReport} />
					<PrivateRoute exact path="/components" component={Components} />
					{/* <PrivateRoute exact path='/dailykanban' component={DailyKanban} /> */}
					<PrivateRoute
						exact
						path="/mixdesignreport"
						component={MixDesignInvoices}
					/>
					<PrivateRoute
						exact
						path="/citydeliveryfee"
						component={CityDeliveryFee}
					/>
					<PrivateRoute exact path="/citynew" component={CityNew} />
					<PrivateRoute exact path="/citydeliveryfee/:id" component={City} />
					<PrivateRoute exact path="/quadrants" component={QuadrantsReport} />
					<PrivateRoute
						exact
						path="/reports/operations/etickets"
						component={ETicketReport}
					/>
					<PrivateRoute
						exact
						path="/driverinvoice/:id"
						component={DriverPaymentInvoice}
					/>
					<PrivateRoute
						exact
						path="/driverpreinvoice"
						component={DriverPaymentPreInvoice}
					/>
					<PrivateRoute
						exact
						path="/driverinvoices"
						component={DriverInvoices}
					/>
					<PrivateRoute
						exact
						path="/opportunitystatus"
						component={OpportunityStatus}
					/>
					<PrivateRoute
						exact
						path="/opportunitystatus/:id"
						component={OpportunityStatusType}
					/>
					<PrivateRoute exact path="/newproposal" component={NewProposal} />
					<PrivateRoute
						exact
						path="/newprospection"
						component={ProspectionRegistration}
					/>
					<PrivateRoute exact path="/proposals" component={Proposals} />
					<PrivateRoute exact path="/proposal/:id" component={Proposal} />
					<PrivateRoute exact path="/heatmap" component={Heatmap} />
					<PrivateRoute
						exact
						path="/heatmapsellers"
						component={HeatmapSellers}
					/>
					<PrivateRoute
						exact
						path="/prospectionlist"
						component={ProspectionList}
					/>
					<PrivateRoute exact path="/prospection/:id" component={Prospection} />
					<PrivateRoute exact path="/paymentpdf/:id" component={PaymentPDF} />
					<PrivateRoute
						exact
						path="/generalpayment/:id"
						component={GeneralPayments}
					/>
					<PrivateRoute
						exact
						path="/generalpaymentpdf/:id"
						component={GeneralPaymentPDF}
					/>
					<PrivateRoute
						exact
						path="/reporttrucktrips"
						component={ReportTruckTrips}
					/>
					<PrivateRoute
						exact
						path="/reports/operations/truckyards"
						component={ReportTruckYards}
					/>
					<PrivateRoute exact path="/lineup" component={Lineup} />
					<PrivateRoute exact path="/salesbyseller" component={SalesBySeller} />
					<PrivateRoute exact path="/trucksmap" component={TruckMap2} />
					{/* <PrivateRoute exact path='/trucksmap' component={TruckMap} /> */}
					<Route exact path="/newemployee" component={NewEmployee} />
					<PrivateRoute exact path="/expensespdf/:id" component={ExpensesPDF} />
					<PrivateRoute
						exact
						path="/expenseswppdf/:id"
						component={ExpensesWPPDF}
					/>
					<PrivateRoute
						exact
						path="/employeewupdf/:id"
						component={EmployeeWriteUpPDF}
					/>
					<PrivateRoute exact path="/newdepartment" component={NewDepartment} />
					<PrivateRoute exact path="/newposition" component={NewPosition} />
					<PrivateRoute exact path="/employees" component={Employees} />
					<PrivateRoute exact path="/employee/:id" component={Employee} />
					<PrivateRoute exact path="/departments" component={Departments} />
					<PrivateRoute exact path="/department/:id" component={Department} />
					<PrivateRoute exact path="/positions" component={Positions} />
					<PrivateRoute exact path="/position/:id" component={Position} />
					<PrivateRoute
						exact
						path="/applicationpdf/:id"
						component={ApplicationEmploymentPDF}
					/>
					<PrivateRoute
						exact
						path="/emailsvalidation"
						component={EmailsValidation}
					/>
					<PrivateRoute
						exact
						path="/emailvalidation/:id"
						component={EmailValidation}
					/>
					<PrivateRoute exact path="/financelist" component={FinanceList} />
					<PrivateRoute exact path="/financenew" component={FinanceNew} />
					<PrivateRoute exact path="/finance/:id" component={Finance} />
					<PrivateRoute exact path="/batchlist" component={BatchList} />
					<PrivateRoute exact path="/batchnew" component={BatchNew} />
					<PrivateRoute exact path="/batch/:id" component={Batch} />
					<PrivateRoute
						exact
						path="/expenses/:id"
						component={EmployeeExpenses}
					/>
					<PrivateRoute
						exact
						path="/expenseswp/:id"
						component={EmployeeExpensesWithoutProof}
					/>
					<PrivateRoute
						exact
						path="/employeewu/:id"
						component={EmployeeWriteUp}
					/>
					<PrivateRoute exact path="/listprice" component={ListPrice} />
					<PrivateRoute exact path="/eticketsjob/:id" component={EticketJob} />
					<PrivateRoute
						exact
						path="/reports/inspections/allinspections"
						component={InspectionReport}
					/>
					<PrivateRoute
						exact
						path="/inspection/:id"
						component={InspectionPDF}
					/>
					<PrivateRoute
						exact
						path="/reports/inspections/defects"
						component={InspectionDefects}
					/>
					<PrivateRoute
						exact
						path="/reportqualitycontrol"
						component={QualityControlReport}
					/>
					<PrivateRoute exact path="/historyreport" component={HistoryReport} />
					<PrivateRoute
						exact
						path="/reports/inspections/failures"
						component={FailuresReport}
					/>
					<PrivateRoute exact path="/workorders" component={WorkOrders} />
					{/* <PrivateRoute exact path='/workorders/:id' component={MyWorkOrders} /> */}
					<PrivateRoute exact path="/workorder/:id" component={WorkOrder} />
					<PrivateRoute exact path="/mechaniclist" component={MechanicList} />
					<PrivateRoute exact path="/newmechanic" component={MechanicNew} />
					<PrivateRoute exact path="/mechanic/:id" component={Mechanic} />
					<PrivateRoute
						exact
						path="/reports/operations/modified"
						component={ETicketModifiedReport}
					/>
					<PrivateRoute
						exact
						path="/e-ticket-modified/:id"
						component={EticketModified}
					/>
					<PrivateRoute
						exact
						path="/workorderpdf/:id"
						component={WorkOrderPDF}
					/>
					<PrivateRoute exact path="/inventorylist" component={InventoryList} />
					<PrivateRoute exact path="/newinventory" component={NewInventory} />
					<PrivateRoute exact path="/inventory/:id" component={Inventory} />
					<PrivateRoute exact path="/providerlist" component={ProviderList} />
					<PrivateRoute exact path="/newprovider" component={NewProvider} />
					<PrivateRoute exact path="/provider/:id" component={Provider2} />
					<PrivateRoute
						exact
						path="/inventoryrequestedlist"
						component={RequestInventoryList}
					/>
					<PrivateRoute
						exact
						path="/newinventoryrequested"
						component={NewInventoryRequested}
					/>
					<PrivateRoute
						exact
						path="/inventoryrequested/:id"
						component={InventoryRequested}
					/>
					<PrivateRoute exact path="/managerlist" component={ManagerList} />
					<PrivateRoute exact path="/newmanager" component={NewManager} />
					<PrivateRoute exact path="/manager/:id" component={Manager} />
					<PrivateRoute
						exact
						path="/logisticsdashboard"
						component={WebLogisticsDashboard}
					/>
					<PrivateRoute
						exact
						path="/reports/logistics/detailed"
						component={LogisticsReportDetailed}
					/>
					<PrivateRoute
						exact
						path="/logisticsdriverlist"
						component={LogisticsDriverList}
					/>
					<PrivateRoute
						exact
						path="/logisticstrucklist"
						component={LogisticsTruckList}
					/>
					<PrivateRoute
						exact
						path="/newlogisticstruck"
						component={NewLogisticsTruck}
					/>
					<PrivateRoute
						exact
						path="/logisticstruck/:id"
						component={LogisticsTruck}
					/>
					<PrivateRoute
						exact
						path="/newlogisticsdriver"
						component={NewLogisticsDriver}
					/>
					<PrivateRoute
						exact
						path="/logisticsdriver/:id"
						component={LogisticsDriver}
					/>
					<PrivateRoute
						exact
						path="/logisticspdf/:id"
						component={LogisticsPDF}
					/>
					<PrivateRoute
						exact
						path="/reports/inspections/driver"
						component={InspectionDriverReport}
					/>
					<PrivateRoute exact path="/smslist" component={SMSList} />
					<PrivateRoute exact path="/sms/:id" component={SMSEdit} />
					<PrivateRoute
						exact
						path="/logisticstrip/:id"
						component={LogisticsTrip}
					/>
					<PrivateRoute exact path="/qclist" component={QCList} />
					<PrivateRoute exact path="/newqc" component={NewQC} />
					<PrivateRoute exact path="/qc/:id" component={QC} />
					<PrivateRoute
						exact
						path="/reports/logistics/tripspertruck"
						component={LogisticsReportTripsPerTruck}
					/>
					<PrivateRoute
						exact
						path="/reports/logistics/tripsperdriver"
						component={LogisticsReportTripsPerDriver}
					/>
					<PrivateRoute
						exact
						path="/reports/logistics/materialperplant"
						component={LogisticsReportMaterialPerPlant}
					/>
					<PrivateRoute
						exact
						path="/reports/logistics/generaltrips"
						component={LogisticsReportTotalGeneralTrips}
					/>
					<PrivateRoute
						exact
						path="/reports/logistics/inspections"
						component={LogisticsReportInspection}
					/>
					<PrivateRoute
						exact
						path="/reports/logistics/inspectionsdriver"
						component={LogisticsReportInspectionDriver}
					/>
					<PrivateRoute
						exact
						path="/reports/logistics/milespertruck"
						component={LogisticsReportMilesPerTruck}
					/>
					<PrivateRoute
						exact
						path="/reports/logistics/milesperdriver"
						component={LogisticsReportMilesPerDriver}
					/>
					<PrivateRoute
						exact
						path="/logistics/inspection/:id"
						component={InspectionLogisticsPDF}
					/>
					<PrivateRoute
						exact
						path="/reports/operations/averagetime"
						component={ReportAverageTimeEticket}
					/>
					<PrivateRoute
						exact
						path="/reports/operations/trips"
						component={ETicketTripsReport}
					/>
					<PrivateRoute
						exact
						path="/reports/operations/tripspertruck"
						component={EticketReportTripsPerTruck}
					/>
					<PrivateRoute
						exact
						path="/reports/operations/tripsperdriver"
						component={EticketReportTripsPerDriver}
					/>
					<PrivateRoute
						exact
						path="/reports/operations/milespertruck"
						component={EticketReportMilesPerTruck}
					/>
					<PrivateRoute
						exact
						path="/reports/operations/milesperdriver"
						component={EticketReportMilesPerDriver}
					/>
					<PrivateRoute
						exact
						path="/opportunity/dashboard"
						component={OpportunityDashboard}
					/>
					<PrivateRoute
						exact
						path="/opportunity/update/:id"
						component={Opportunity}
					/>
					<PrivateRoute
						exact
						path="/opportunity/quadrants/:id"
						component={OpportunityQuadrants}
					/>
					<PrivateRoute
						exact
						path="/loan/dashboard"
						component={LoanDashboard}
					/>
					<PrivateRoute exact path="/loan/new" component={NewLoan} />
					<PrivateRoute exact path="/loan/update/:id" component={Loan} />
					<PrivateRoute
						exact
						path="/reports/loan/payments"
						component={LoanPaymentsReport}
					/>
					<PrivateRoute
						exact
						path="/reports/loan/payments/detailed"
						component={LoanPaymentsDetailed}
					/>
					<PrivateRoute
						exact
						path="/office/vehicle/list"
						component={VehicleList}
					/>
					<PrivateRoute
						exact
						path="/office/vehicle/new"
						component={NewVehicle}
					/>
					<PrivateRoute
						exact
						path="/office/vehicle/update/:id"
						component={Vehicle}
					/>
					<PrivateRoute
						exact
						path="/office/truck/list"
						component={OfficeTruckList}
					/>
					<PrivateRoute
						exact
						path="/office/truck/new"
						component={NewOfficeTruck}
					/>
					<PrivateRoute
						exact
						path="/office/truck/update/:id"
						component={OfficeTruck}
					/>
					<PrivateRoute
						exact
						path="/lien/dashboard"
						component={LienDashboard}
					/>
					<PrivateRoute exact path="/lien/new" component={NewLien} />
					<PrivateRoute exact path="/lien/update/:id" component={Lien} />

					<PrivateRoute exact path="/lien/pdf/:id" component={LienPDF} />
					<PrivateRoute
						exact
						path="/claim/dashboard"
						component={ClaimDashboard}
					/>
					<PrivateRoute exact path="/claim/new" component={NewClaim} />
					<PrivateRoute exact path="/claim/update/:id" component={Claim} />
					<PrivateRoute
						exact
						path="/transaction/dashboard"
						component={TransactionDashboard}
					/>
					<PrivateRoute
						exact
						path="/reports/operations/dieselloading"
						component={DieselLoadingReport}
					/>
					<PrivateRoute
						exact
						path="/directorsmanagement"
						component={DirectorsManagement}
					/>
					<PrivateRoute
						exact
						path="/logistics/pdf/:id"
						component={LogisticsMaterialPDF}
					/>
					<PrivateRoute
						exact
						path="/reports/job/yardsbyweek"
						component={JobYardsByWeekReport}
					/>
				</Switch>
			</BrowserRouter>
		</Provider>
	);
};

export default Routes;
