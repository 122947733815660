import { Button, Checkbox, Col, Form, Input, InputNumber, Row } from "antd";
import React, { useState } from "react";

const JobCheckIn = ({ values, setCheckInValues, handleCheckInCancel }) => {
	const [measurement, setMeasurement] = useState(true);
	const [readyForTruck, setReadyForTruck] = useState(true);
	const [lightTowerPositions, setLightTowerPositions] = useState(false);
	const [disabled, setDisabled] = useState(
		values.jobStatus === "Operations done (AR)"
	);
	const [form] = Form.useForm();

	const onChangeMeasurement = (e) => {
		setMeasurement(!e.target.checked);
		if (e.target.checked === false) {
			form.setFieldValue("measurementYards", undefined);
		}
	};

	const onChangeReadyForTruck = (e) => {
		setReadyForTruck(!e.target.checked);
		if (e.target.checked === false) {
			form.setFieldValue("readyForTruckComments", undefined);
		}
	};

	const onChangeLightTowerPositions = (e) => {
		setLightTowerPositions(e.target.checked);
		if (e.target.checked === false) {
			form.setFieldValue("lightTowerPositionsNumberTag", undefined);
			form.setFieldValue("lightTowerPositionsBrand", undefined);
		}
	};

	const onFinish = () => {
		const values = form.getFieldsValue(true);
		setCheckInValues({
			measurement: values.measurement || false,
			measurementYards: values.measurementYards || undefined,
			readyForTruck: values.readyForTruck || false,
			readyForTruckComments: values.readyForTruckComments || undefined,
			lightTowerPositions: values.lightTowerPositions || false,
			lightTowerPositionsNumberTag:
				values.lightTowerPositionsNumberTag || undefined,
			lightTowerPositionsBrand: values.lightTowerPositionsBrand || undefined,
			pour: values.pour || false,
		});
		handleCheckInCancel();
	};

	return (
		<Form
			form={form}
			layout="vertical"
			initialValues={values}
			onFinish={onFinish}
		>
			<Row gutter={16}>
				<Col span={24}>
					<Form.Item label="" name="measurement" valuePropName="checked">
						<Checkbox onChange={onChangeMeasurement} disabled={disabled}>
							Measurement
						</Checkbox>
					</Form.Item>
				</Col>
				<Col span={24} md={12}>
					<Form.Item
						label="Yards"
						name="measurementYards"
						rules={[{ required: true, message: "Yards are required" }]}
					>
						<InputNumber
							size="large"
							style={{ width: "100%" }}
							formatter={(value) =>
								`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							}
							parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							disabled={measurement || disabled}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={24}>
					<Form.Item label="" name="readyForTruck" valuePropName="checked">
						<Checkbox onChange={onChangeReadyForTruck} disabled={disabled}>
							Ready For Truck
						</Checkbox>
					</Form.Item>
				</Col>
				{readyForTruck && (
					<Col span={24} md={12}>
						<Form.Item
							label="Comments"
							name="readyForTruckComments"
							rules={[{ required: true, message: "Comments are required" }]}
						>
							<Input size="large" disabled={disabled} />
						</Form.Item>
					</Col>
				)}
			</Row>
			<Row gutter={16}>
				<Col span={24}>
					<Form.Item
						label=""
						name="lightTowerPositions"
						valuePropName="checked"
					>
						<Checkbox
							onChange={onChangeLightTowerPositions}
							disabled={disabled}
						>
							Light Tower Positions
						</Checkbox>
					</Form.Item>
				</Col>
				{lightTowerPositions && (
					<Col span={24} md={12}>
						<Form.Item
							label="Number Tag"
							name="lightTowerPositionsNumberTag"
							rules={[{ required: true, message: "Number Tag required" }]}
						>
							<Input size="large" disabled={disabled} />
						</Form.Item>
					</Col>
				)}
				{lightTowerPositions && (
					<Col span={24} md={12}>
						<Form.Item
							label="Brand"
							name="lightTowerPositionsBrand"
							rules={[{ required: true, message: "Brand required" }]}
						>
							<Input size="large" disabled={disabled} />
						</Form.Item>
					</Col>
				)}
			</Row>
			<Row gutter={16}>
				<Col span={24}>
					<Form.Item label="" name="pour" valuePropName="checked">
						<Checkbox
							disabled={
								!(values.jobStatus === "ok" || values.jobStatus === "Active") ||
								disabled
							}
						>
							Pour
						</Checkbox>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={[16, 8]}>
				<Col>
					<Button
						type="primary"
						htmlType="submit"
						size="large"
						disabled={disabled}
					>
						Save
					</Button>
				</Col>
			</Row>
		</Form>
	);
};

export default JobCheckIn;
